let Translate = {
    "ar": {
        home: {
            title: '',
            btn: ' قم بالطلب الان    '
        },
        about: {
            header: 'من نــحن',
            title: 'نحن نقوم بتطبيق الموقع والجوال',
            dec: 'مرحبًا بكم في Vigtas ، شركة تطوير برمجيات رائدة في طليعة الثورة الرقمية. من خلال شغفنا بالابتكار والالتزام بالتميز ، نحن متخصصون في تطوير الويب والجوال ، والذكاء الاصطناعي ، و Blockchain ، والتعلم الآلي ، وحلول إدارة تطبيقات الأجهزة المحمولة. منذ بدايتنا ، قمنا بتمكين الشركات والمؤسسات لتسخير قوة التكنولوجيا والبقاء في الطليعة في مشهد رقمي دائم التطور.',
            btn: 'تفاصيل اكتر',
        },
        Service: {
            title: 'خدماتنا ',
            dec: 'Vigtas هي شركة برمجيات ديناميكية مكرسة لتحويل الأفكار إلى حلول رقمية مؤثرة. تشمل مجموعتنا الشاملة من الخدمات تطوير البرامج المخصصة ، وتصميم تطبيقات الويب والجوال المتطورة ، وحلول التجارة الإلكترونية القوية ، وتكامل الذكاء الاصطناعي المبتكر. مع فريق شغوف من الخبراء ، نحن نمكّن الشركات من الازدهار في العصر الرقمي من خلال تقديم خدمات برمجية مخصصة وفعالة وقابلة للتطوير تؤدي إلى النجاح',
            readMore: '  اقرأ أكثر',
            ServiceWorker: 'هل تفكر في بدء مشروع جديد ؟  ',
            btnContactUs: 'أتصل بــنا'

        },
        AboutList: {
            Customer: ' نهج يركز على العملاء',
            CustomerDes: "في Vigtas ، يقع نجاح عملائنا في صميم كل ما نقوم به. نحن نعطي الأولوية لفهم احتياجاتهم وتحدياتهم وأهدافهم الفريدة ، ونصمم حلولنا لتلبية تلك المتطلبات المحددة. من خلال التواصل المفتوح والشفافية ، نعزز شراكات قوية ونحقق نتائج تفوق التوقعات.",
            Innovation: "الابتكار والتعاون",
            InnovationDes: "الابتكار في حمضنا النووي. نحن نزرع ثقافة التعلم المستمر والاستكشاف ، ونشجع أعضاء فريقنا على تخطي الحدود والتفكير خارج الصندوق. من خلال التعاون مع عملائنا وأصحاب المصلحة ، نقوم بإنشاء حلول تعالج مشاكل العالم الحقيقي وتخلق قيمة دائمة.",
            Social: "مسؤولية اجتماعية",
            SocialDes: "كشركة مسؤولة اجتماعيًا ، نحن ملتزمون باستخدام التكنولوجيا من أجل التغيير الإيجابي. نحن ندعم بنشاط المبادرات التي تعزز الاستدامة والتنوع والشمولية ، والمساهمة في عالم أفضل وأكثر إنصافًا."
            , btnContactUs: 'أتصل بــنا'

        }
    },
    "en": {
        home: {
            title: 'Well Known Development Company',
            btn: 'Make order'
        },
        about: {
            header: 'About US',
            btn: 'More Details',
            title: 'We do Website & Mobil App',
            dec: "Welcome to Vigtas, a leading software development company at the forefront of the digital revolution. With a passion for innovation and a commitment to excellence, we specialize in Web and Mobile Developments, AI, Blockchain, Machine Learning, and Mobile App Management solutions. Since our inception, we have been empowering businesses and organizations to harness the power of technology and stay ahead in an ever-evolving digital landscape."
        },
        Service: {
            title: 'Our Service',
            dec: "Vigtas is a dynamic software company dedicated to transforming ideas into impactful digital solutions. Our comprehensive range of services includes custom software development, cutting-edge web and mobile app design, robust e-commerce solutions, and innovative AI integration. With a passionate team of experts, we empower businesses to thrive in the digital age by delivering tailored, efficient, and scalable software services that drive success.",
            readMore: 'Read more',
            ServiceWorker: 'Have a project in mind? Let’s get to work.',
            btnContactUs: '  Contact Us'
        },
        AboutList: {
            Customer: 'Customer-Centric Approach',
            CustomerDes: 'At Vigtas, our clients success is at the heart of everything we do. We prioritize understanding their unique needs, challenges, and goals, and tailor our solutions to meet those specific requirements. With open communication and transparency, we foster strong partnerships and deliver outcomes that exceed expectations.',
            Innovation: 'Innovation and Collaboration ',
            InnovationDes: 'Innovation is in our DNA. We cultivate a culture of continuous learning and exploration, encouraging our team members to push boundaries and think outside the box. By collaborating with our clients and stakeholders, we create solutions that address real-world problems and create lasting value.',
            Social: "Social Responsibility ",
            btnContactUs: '  Contact Us',
            SocialDes: "As a socially responsible company, we are committed to using technology for positive change. We actively support initiatives that promote sustainability, diversity, and inclusivity, contributing to a better and more equitable world."
        }

    }
}

export default Translate

