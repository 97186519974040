import React, { useContext } from 'react'
import './footer.scss'
import img from './../../assets/Img';
import { Link } from 'react-router-dom';
import Translate from '../NavBar/Translate';
import { LocalizationContext } from '../../context/LangChange';
import Icons from '../../constants/Icons';
const Footer = () => {
  let { isLang } = useContext(LocalizationContext);

  const TSidebar = Translate[isLang]?.Sidebar?.dec || '';
  const TSidebarTitle = Translate[isLang]?.Sidebar?.title || '';
  const TFooterQuick = Translate[isLang]?.footer?.Quick || '';
  const TFooterHome = Translate[isLang]?.footer?.home || '';
  const TFooterAbout = Translate[isLang]?.footer?.about || '';
  const TFooterServices = Translate[isLang]?.footer?.services || '';
  const TFooterContact = Translate[isLang]?.footer?.contact || '';
  const TFooterContactUS = Translate[isLang]?.Sidebar?.ContactUS || '';
  const TFooterLocation = Translate[isLang]?.navbarTop?.location1 || '';
  const TFooterLocation2 = Translate[isLang]?.navbarTop?.location2 || '';

  return (
    <div className='footer-top'>
      <footer className="footer-area theme-footer-three " dir={isLang == "en" ? 'ltr' : 'rtl'}>
        {<img className="footer-shape shape-1b" src={img.star1} alt="shape" />}
        {<img className="footer-shape shape-2b" src={img.star1} alt="shape" />}
        {<img className="footer-shape shape-3b" src={img.star3} alt="shape" />}
        {<img className="footer-shape shape-4b" src={img.star2} alt="shape" />}
        {<img className="footer-shape shape-5b" src={img.star2} alt="shape" />}
        <img className="fot-shape-one" src={img.AnimationLine} alt="footer shape" />

        <div className="container">
          <div className="row gx-4 gx-xxl-5 mb-10">
            <div className="col-xxl-3 col-md-4 aos-init mt-3 aos-animate" data-aos="fade-up">
              <div className="footer__widget Vigtas mb-40">
                <Link className="logo-title" to={'/'}>Vigtas.</Link>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-4 col-md-6 mt-3 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
              <div className="footer__widget mb-40">
                <h4 className="widget-title">{TSidebarTitle}</h4>
                <p className="fs-17 text-white mb-0 pb-20"> {TSidebar}</p>
              </div>
              {/* <div className="footer__social d-flex gap-3 justify-content-start align-items-center flex-row " style={{ marginTop: '30px' }}>
              <a href="#">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a href="#">
                <i className="fab fa-youtube"></i>
              </a>
            </div> */}
            </div>

            <div className="col-xxl-3 col-md-4 aos-init mt-3 aos-animate" data-aos="fade-up" data-aos-delay="100">
              <div className="footer__widget mb-40">
                <h4 className="widget-title">{TFooterQuick}</h4>
                <div className="nav flex-column">
                  <Link to={'/'} className="nav-link px-0"  >{TFooterHome}</Link>
                  <Link to={'/about'} className="nav-link px-0"  >{TFooterAbout}</Link>
                  <Link to={'/services'} className="nav-link px-0"  >{TFooterServices}</Link>
                  <Link to={'/contact'} className="nav-link px-0" >{TFooterContact}</Link>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-md-4 aos-init mt-3 aos-animate" data-aos="fade-up">
              <div className="footer__widget ">
                <h4 className="widget-title">{TFooterContactUS}</h4>
                <ul className="fot-list">
                  <li>
                    <a href="# " className=' flex gap-3 flex-row align-items-center'><i className="fa-solid fa-chevron-right"></i>{TFooterLocation2} <br />  {TFooterLocation} </a>
                  </li>
                  <li>
                    <a className=' flex gap-3 flex-row align-items-center' href="mailto:info@vigtas.com" target="_blank" rel="noreferrer" ><i className="fa-solid fa-chevron-right"></i> info@vigtas.com</a>
                  </li>
                  <li>
                    <a className=' flex gap-3 flex-row align-items-center' href="tel:+201090105214" target="_blank" rel="noreferrer" ><i className="fa-solid fa-chevron-right"></i> <span dir='ltr' >+20 109 010 5214 </span> </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-three">
          <div className="container">
            <div className="row  align-items-center">
              <div className="col-xl-4 col-lg-6 pb-30">

              </div>
              <div className="col-xl-4 col-lg-6  text-center pb-30">
                <p className="text-white mb-20">
                  VIGTAS  © 2023 , All Rights Reserved</p>
              </div>
              <div className="col-xl-4 col-lg-6 d-flex justify-content-center justify-content-lg-start justify-content-xl-end pb-30">

              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="top  "
                data-aos="zoom-in"
                data-aos-anchor-placement="top-bottom"
                data-aos-duration="1500"  onClick={() => window.scroll(0, 0)} >
                <Icons.TopArrow />
            </div>
    </div>
  )
}

export default Footer
