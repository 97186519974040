import Icons from "../../constants/Icons";
import Contact from './../../Page/Contact/Contact';

const routes = [
    {
        path: "/",
        nameEn: "Home",
        nameAr: "  الرئيسه",
        icon: <Icons.Home className="logoSvg" style={{ width: 20 }} />,
    }, 
    {
        path: "/about",
        nameEn: "about Us",
        nameAr: "من نحــن ",
        icon: <Icons.Aboutus className="logoSvg" style={{ width: 20 }} />,
    }, 
    {
        path: "/services",
        nameEn: "Our Services",
        nameAr: "خدماتنا",
        icon: <Icons.Service  className="logoSvg" style={{ width: 20 }} />,
    },
    {
        path: "/contact",
        nameEn: "Contact  ",
        nameAr: "اتصل بنا",
        icon: <Icons.ContactUs className="logoSvg" style={{ width: 20 }} />,
    },
 

];

export default routes