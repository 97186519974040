let Translate = {
    "ar": {
        titleVision:'رؤيتنا',
        titleMission:'مهمتنا',
        Vision: `تتمثل رؤيتنا في قيادة زمام التحول الرقمي من خلال تطوير حلول رائدة ترسم ملامح المستقبل. نحن نتخيل عالماً حيث تعمل خبرتنا في تطوير الويب والجوال ، والذكاء الاصطناعي ، و Blockchain ، والتعلم الآلي ، وإدارة تطبيقات الأجهزة المحمولة على تمكين الشركات عبر الصناعات من تبني الابتكار وتحسين العمليات وخلق قيمة دائمة. من خلال تعزيز ثقافة التعلم المستمر والقدرة على التكيف ، نهدف إلى أن نكون في طليعة التقنيات الناشئة ، ودفع التغيير الإيجابي لعملائنا والمجتمع ككل.`,
        Mission:'تتمثل مهمتنا في Vigtas في تمكين عملائنا من خلال الحلول التقنية المتطورة التي تدفع بالنمو والكفاءة والاستدامة. نهدف إلى أن نكون شريكًا موثوقًا به ، ونستفيد من خبرتنا في إنشاء تجارب تحويلية تدفع الشركات إلى آفاق جديدة. من خلال تفانينا في الابتكار والتعاون ورضا العملاء ، نسعى جاهدين لبناء علاقات دائمة يكون لها تأثير إيجابي على نجاح عملائنا.',
        title:'من نحن' ,
        dec:'مرحبًا بكم في Vigtas ، شركة تطوير برمجيات رائدة في طليعة الثورة الرقمية. من خلال شغفنا بالابتكار والالتزام بالتميز ، نحن متخصصون في تطوير الويب والجوال ، والذكاء الاصطناعي ، و Blockchain ، والتعلم الآلي ، وحلول إدارة تطبيقات الأجهزة المحمولة. منذ بدايتنا ، قمنا بتمكين الشركات والمؤسسات لتسخير قوة التكنولوجيا والبقاء في الطليعة في مشهد رقمي دائم التطور.'
    },
    "en": {
        titleVision:'Our Vision',
        titleMission:'Our Mission',
        Vision:'Our vision is to lead the charge in digital transformation by developing pioneering solutions that shape the future. We envision a world where our expertise in Web and Mobile Developments, AI, Blockchain, Machine Learning, and Mobile App Management empowers businesses across industries to embrace innovation, optimize operations, and create lasting value. By fostering a culture of continuous learning and adaptability, we aim to be at the forefront of emerging technologies, driving positive change for our clients and society at large.',
        Mission:`At Vigtas, our mission is to empower our clients with cutting-edge technology solutions that drive growth, efficiency, and sustainability. We aim to be a trusted partner, leveraging our expertise to create transformative experiences that propel businesses to new heights. Through our dedication to innovation, collaboration, and customer satisfaction, we strive to build lasting relationships that make a positive impact on our clients' success.`,
        title:'About Us',
        dec:' Welcome to Vigtas, a leading software development company at the forefront of the digital revolution. With a passion for innovation and a commitment to excellence, we specialize in Web and Mobile Developments, AI, Blockchain, Machine Learning, and Mobile App Management solutions. Since our inception, we have been empowering businesses and organizations to harness the power of technology and stay ahead in an ever-evolving digital landscape.     '
    }
}

export default Translate