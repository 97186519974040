import React, { useEffect, useState } from 'react'
import Component from './../constants/Component';
import { Outlet, useLocation } from 'react-router-dom';

const Vigtas = () => {
  const location = useLocation();  
  const [navBarBg, setnavBarBg] = useState('navbar-nav')
  
  useEffect(() => {
      if (location.pathname !== '/') {
          setnavBarBg('navbar-navOnthorPAge');
      } else {
          setnavBarBg('navbar-navOnthorPAge')
      }
  }, [location.pathname])
  return (
    <div>
      <Component.NavBar  navbarColor={navBarBg} />
      <Outlet />
      <Component.Footer />
    </div>
  )
}

export default Vigtas
