
import Vigtas from './../layout/Vigtas';
import Home from './../Page/Home/Home';
import About from './../Page/About/About';
import NavBar from './../components/NavBar/NavBar';
import Footer from './../components/Footer/Footer';
import Service from './../Page/service/Service';
import Contact from './../Page/Contact/Contact';
 import HeaderBase from './../components/HeaderBase';
import Details from './../Page/service/Details';

const Component = {
    // Layout
    Vigtas,
    Home,
    About,
    NavBar,
    Footer,
    Service,
    Contact,
    HeaderBase,
    Details
}

export default Component


