import React, { useContext, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import img from './../../assets/Img';
import AOS from 'aos';
import { Link } from 'react-router-dom';
import { LocalizationContext } from '../../context/LangChange';
import Translate from './Translate'
import { Player } from '@lottiefiles/react-lottie-player';
import  AboutAnimation from '../../assets/animation_llf9tyns.json';

const AboutLast = () => {
    let { isLang } = useContext(LocalizationContext);

    useEffect(() => {
        AOS.init({
            duration: '1500'
        });
    }, []);
    return (

        <>
            <div className='app__about overflow-hidden' style={{ backgroundColor: 'rgba(16, 76, 186, 0.05)' }}>
                <div className="title" data-aos="flip-left"
                    data-aos-anchor-placement="bottom-bottom">
                    <h2 className="sect-title text-center">{ Translate[isLang]?.AboutList?.Customer}</h2>
                </div>

                <div className="content ">
                    <Container>
                        <Row dir={isLang == "en" ? 'ltr' : 'rtl'}>
                            <Col xl={5} lg={5} md={12} sm={12} className={`  ${isLang == "en" ? '' : ' offset-lg-1'} `}>
                                <img src={img.Servicehome} className='w-100' height={'350px'} style={{ objectFit: 'contain' }} alt="image about us" data-aos="fade-up" />  
        {/*                         <Player
                                    // src="https://assets3.lottiefiles.com/packages/lf20_ZJR0xnKKys.json"
                                    src={AboutAnimation}
                                    autoplay
                                    loop
                                /> */}
                            </Col>

                            <Col xl={6} lg={6} md={12} sm={12}  className={`mt-8 ${isLang == "ar" ? '' : ' offset-lg-1'} d-flex flex-column justify-content-center`}>
                                <div className="block-style-seven pl-90 mb-45 pl-md-0">
                                    {/* <h6 className="sub-title-two mb-20" data-aos="fade-up" >About Us</h6> */}
                                    {/* <h2 className="sect-title mb-20" data-aos="fade-up" data-aos-delay={`  200`}  >We do Website &amp; Mobil App</h2> */}
                                    <p  dir={isLang==="en"?'ltr':'rtl'} data-aos="fade-up" data-aos-delay={`  200`} style={{ marginBottom: '40px' }} >
                                        { Translate[isLang]?.AboutList?.CustomerDes}
                                    </p>
                                    <Link to={'/contact'}   >
                                        <div className="btn__main" data-aos="fade-up">
                                            <button className="btn theme_btn"> { Translate[isLang]?.AboutList?.btnContactUs}  </button>
                                        </div>
                                    </Link>
                                </div>
                            </Col>

                        </Row>
                    </Container>
                </div>
            </div>


            <div className='app__about overflow-hidden'  >
                <div className="title" data-aos="flip-left"
                    data-aos-anchor-placement="bottom-bottom">
                    <h2 className="sect-title text-center">    { Translate[isLang]?.AboutList?.Innovation}  </h2>
                </div>

                <div className="content ">
                    <Container>
                        <Row >
                            <Col xl={5} lg={5} md={12} sm={12} className='mt-5'>
                                <img src={img.InnovationService1} className='w-100 ' height={'350px'} style={{ objectFit: 'contain' }} alt="image about us" data-aos="fade-up" />
                            </Col>

                            <Col xl={6} lg={6} md={12} sm={12}
                                className='  offset-lg-1 d-flex flex-column justify-content-center'
                            >
                                <div className="block-style-seven pl-90 mb-45 pl-md-0">
                                    {/* <h6 className="sub-title-two mb-20" data-aos="fade-up" >About Us</h6> */}
                                    {/* <h2 className="sect-title mb-20" data-aos="fade-up" data-aos-delay={`  200`}  >We do Website &amp; Mobil App</h2> */}
                                    <p  dir={isLang==="en"?'ltr':'rtl'}data-aos="fade-up" data-aos-delay={`  200`} style={{ marginBottom: '40px' }}>
                                        { Translate[isLang]?.AboutList?.InnovationDes}
                                    </p>

                                    <Link to={'/contact'}  >
                                        <div className="btn__main" data-aos="fade-up">
                                            <button className="btn theme_btn"  > { Translate[isLang]?.AboutList?.btnContactUs} </button>
                                        </div>
                                    </Link>
                                </div>
                            </Col>

                        </Row>
                    </Container>
                </div>
            </div>

            <div className='app__about overflow-hidden' style={{ backgroundColor: 'rgba(16, 76, 186, 0.05)' }}>
                <div className="title" data-aos="flip-left"
                    data-aos-anchor-placement="bottom-bottom">
                    <h2 className="sect-title text-center">  { Translate[isLang]?.AboutList?.Social}   </h2>
                </div>

                <div className="content ">
                    <Container>
                        <Row dir={isLang == "en" ? 'ltr' : 'rtl'}>
                            <Col xl={5} lg={5} md={12} sm={12} className={`mt-8 ${isLang == "en" ? '' : ' offset-lg-1'} `}>
                                <img src={img.socialService1} className='w-100' alt="image about us" data-aos="fade-up" />
                            </Col>

                            <Col xl={6} lg={6} md={12} sm={12}

                                className={`mt-8 ${isLang == "ar" ? '' : ' offset-lg-1'} d-flex flex-column justify-content-center`}
                            >
                                <div className="block-style-seven pl-90 mb-45 pl-md-0">
                                    {/* <h6 className="sub-title-two mb-20" data-aos="fade-up" >About Us</h6> */}
                                    {/* <h2 className="sect-title mb-20" data-aos="fade-up" data-aos-delay={`  200`}  >We do Website &amp; Mobil App</h2> */}
                                    <p  dir={isLang==="en"?'ltr':'rtl'}data-aos="fade-up" data-aos-delay={`  200`} style={{ marginBottom: '40px' }} >
                                        { Translate[isLang]?.AboutList?.SocialDes}
                                    </p>
                                    <Link to={'/contact'}   >
                                        <div className="btn__main" data-aos="fade-up">
                                            <button className="btn theme_btn"  > { Translate[isLang]?.AboutList?.btnContactUs}  </button>

                                        </div>
                                    </Link>
                                </div>
                            </Col>

                        </Row>
                    </Container>
                </div>
            </div>

        </>
    )
}

export default AboutLast
