import AOS from 'aos';
import React, { useContext, useEffect } from 'react';
import Component from '../../constants/Component';
import Services from '../Home/Services';
import './style.scss';
import { LocalizationContext } from '../../context/LangChange';

import Translate from './Translate'

const Service = () => {
  let { isLang } = useContext(LocalizationContext);

  useEffect(() => {
    window.scroll(0, 0)
    AOS.init({
      duration: '1500'
    });
  }, []); 
  return (
    <div>
      <Component.HeaderBase /* title={'Our Service   '} */ title={ Translate[isLang]?.title} />
      <div className="mt-5  ">
        <Services />
      </div> 
    </div >
  )
}

export default Service
