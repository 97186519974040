let Translate = {
    "ar": {
        titleVision: 'رؤيتنا',
        titleMission: 'مهمتنا',
        Vision: `تتمثل رؤيتنا في قيادة زمام التحول الرقمي من خلال تطوير حلول رائدة ترسم ملامح المستقبل. نحن نتخيل عالماً حيث تعمل خبرتنا في تطوير الويب والجوال ، والذكاء الاصطناعي ، و Blockchain ، والتعلم الآلي ، وإدارة تطبيقات الأجهزة المحمولة على تمكين الشركات عبر الصناعات من تبني الابتكار وتحسين العمليات وخلق قيمة دائمة. من خلال تعزيز ثقافة التعلم المستمر والقدرة على التكيف ، نهدف إلى أن نكون في طليعة التقنيات الناشئة ، ودفع التغيير الإيجابي لعملائنا والمجتمع ككل.`,
        Mission: 'تتمثل مهمتنا في Vigtas في تمكين عملائنا من خلال الحلول التقنية المتطورة التي تدفع بالنمو والكفاءة والاستدامة. نهدف إلى أن نكون شريكًا موثوقًا به ، ونستفيد من خبرتنا في إنشاء تجارب تحويلية تدفع الشركات إلى آفاق جديدة. من خلال تفانينا في الابتكار والتعاون ورضا العملاء ، نسعى جاهدين لبناء علاقات دائمة يكون لها تأثير إيجابي على نجاح عملائنا.',
        title: 'خدماتنا  ',
        dec: 'مرحبًا بكم في Vigtas ، شركة تطوير برمجيات رائدة في طليعة الثورة الرقمية. من خلال شغفنا بالابتكار والالتزام بالتميز ، نحن متخصصون في تطوير الويب والجوال ، والذكاء الاصطناعي ، و Blockchain ، والتعلم الآلي ، وحلول إدارة تطبيقات الأجهزة المحمولة. منذ بدايتنا ، قمنا بتمكين الشركات والمؤسسات لتسخير قوة التكنولوجيا والبقاء في الطليعة في مشهد رقمي دائم التطور.'
        , Service1: 'تطوير الويب والجوال'
        , Service2: 'إدارة تطبيقات الجوال  '
        , Service3: 'الذكاء الاصطناعي والتعلم الآلي '
        , Service4: 'بلوكشين',
        WebApp: {
            title: ' تطوير الويب والجوال',
            dec: `في Vigtas ، نفخر بإنشاء تطبيقات الويب والجوال المتطورة التي تمزج بين الوظائف السلسة والتصميم المذهل. يعمل فريقنا من المطورين والمصممين وخبراء تجربة المستخدم المهرة بشكل تعاوني لتقديم تطبيقات بديهية وسريعة الاستجابة وسهلة الاستخدام. سواء أكان موقعًا إلكترونيًا أو منصة تجارة إلكترونية أو تطبيقًا للهاتف المحمول ، فإننا نضمن أن تتجاوز حلولنا توقعات عملائنا وتعزز تواجدهم عبر الإنترنت.`
        },

        Mobile: {
            title: ' إدارة تطبيقات الجوال    ',
            Specialization: 'تخصص إدارة تطبيقات الجوال :',
            SpecializationTitle: 'في Vigtas ، ندرك أن نجاح تطبيق الهاتف المحمول يتجاوز تطويره الأولي. تشمل خدماتنا المتخصصة لإدارة تطبيقات الأجهزة المحمولة كل مرحلة من دورة حياة التطبيق:',
            dec: `تضمن خدمات إدارة تطبيقات الأجهزة المحمولة لدينا أن تطبيقات عملائنا تعمل بأعلى أداء وأن تظل مواكبة لأحدث التقنيات. من تحديثات التطبيقات وتحسين الأداء إلى تحسينات الأمان ، ندير دورة الحياة الكاملة لتطبيقات الأجهزة المحمولة ، مما يسمح لعملائنا بالتركيز على أهداف أعمالهم الأساسية.`,
            SpecializationList: [
                {
                    title: '  نشر التطبيقات وتوزيعها :',
                    dec: `نحن نضمن التوزيع السلس للتطبيقات عبر متاجر التطبيقات المختلفة ، مع ضمان الامتثال للإرشادات وتحسين قابلية اكتشاف التطبيقات.`
                },
                {
                    title: `  مراقبة الأداء  :`,
                    dec: `توفر حلول المراقبة الخاصة بنا رؤى في الوقت الفعلي لأداء التطبيق ، مما يسمح بالتعرف الاستباقي وحل مشكلات الأداء لتقديم تجربة مستخدم استثنائية.`
                },

                {
                    title: `  أمان التطبيق: `,
                    dec: `للأمان أهمية قصوى. نقوم بتنفيذ إجراءات أمنية قوية ، بما في ذلك التشفير والمصادقة والتفويض لحماية بيانات المستخدم ووظائف التطبيق.`
                },
                {
                    title: ` تحديثات التطبيق وصيانته:  `,
                    dec: `يتعامل فريقنا مع التحديثات المنتظمة وإصلاحات الأخطاء والتحسينات للحفاظ على تطبيقك محدثًا وعمليًا ، مما يعزز تفاعل المستخدمين ورضاهم.`
                },
                {
                    title: `تحليلات المستخدم: `,
                    dec: `نقوم بدمج أدوات التحليلات لمساعدتك على جمع الأفكار حول سلوك المستخدم وتفضيلاته وتفاعلاته ، مما يتيح اتخاذ قرارات تستند إلى البيانات لتحسين التطبيق`
                },
                {
                    title: `  الإبلاغ عن الأعطال وتصحيح الأخطاء: `,
                    dec: `تشمل حلولنا أدوات الإبلاغ عن الأعطال التي تحدد أعطال التطبيق والأخطاء ، مما يتيح تصحيح الأخطاء بسرعة ويقلل من اضطرابات المستخدم.`
                },
                {
                    title: `النسخ الاحتياطي واستعادة البيانات:  `,
                    dec: `نقوم بتنفيذ استراتيجيات النسخ الاحتياطي واستعادة البيانات لضمان الحد الأدنى من فقدان البيانات في حالة وقوع أحداث غير متوقعة ، مما يضمن استمرارية الأعمال.`
                },
            ],

            SkillsList: [
                {
                    title: `خبرة دورة حياة تطبيقات الأجهزة المحمولة: `,
                    dec: `يمتلك فريقنا فهمًا عميقًا لدورة حياة التطبيق الكاملة ، من الفكرة إلى إدارة ما بعد الإطلاق ، مما يسمح لنا بتقديم حلول شاملة وفعالة.`
                },
                {
                    title: `الكفاءة التقنية:  `,
                    dec: `  نحن بارعون في لغات وأطر وأدوات تطوير تطبيقات الأجهزة المحمولة ، مما يمكننا من إدارة التطبيقات عبر مختلف المنصات والتقنيات.`
                },
                {
                    title: `الحلول المبتكرة: `,
                    dec: `يدفعنا التزامنا بالابتكار إلى اعتماد أحدث التقنيات ، مما يضمن استفادة عملائنا من أكثر ممارسات إدارة تطبيقات الأجهزة المحمولة تقدمًا.`
                },
                {
                    title: `   حل المشكلات :`,
                    dec: `  تتطلب تحديات إدارة التطبيقات المعقدة حل المشكلات بطريقة إبداعية. نحن نتميز في تحديد المشكلات وتشخيص الأسباب الجذرية وابتكار العلاجات الفعالة.`
                 },
                {
                    title: `القدرة على التكيف: `,
                    dec: `مشهد الجوال في تطور مستمر . نبقى قابلين للتكيف ورشيقة ، ونعدل استراتيجياتنا لمواكبة الاتجاهات والتقنيات المتغيرة.`
                 },
                {
                    title: `التعاون :`,
                    dec:    `نحن نعمل بشكل وثيق مع العملاء ، ونفهم أهداف أعمالهم ونترجمها إلى استراتيجيات إدارة تطبيقات الجوال التي تقود إلى النجاح.`
                 },
                {
                    title: `التركيز الذي يركز على المستخدم :`,
                    dec: `نعطي الأولوية لتجربة المستخدمين النهائيين ، بهدف توفير تطبيقات ليست وظيفية فحسب ، بل أيضًا ممتعة وسهلة الاستخدام.`
                 },
            ],
            SkillsTitle: 'المهارات التي تميزنا:  ',
            SkillsDec: `في Vigtas ، ندرك أن إدارة تطبيقات الأجهزة المحمولة هي رحلة مستمرة ، ونحن ملتزمون بتوجيهك في كل خطوة. مهمتنا هي تحسين أداء تطبيقك وأمانه ورضا المستخدم مع السماح لك بالتركيز على أهداف عملك الأساسية. انضم إلينا في الارتقاء بممارسات إدارة تطبيقات الجوال الخاصة بك إلى آفاق جديدة.`

        },

        AI: {
            title: 'الذكاء الاصطناعي والتعلم الآلي',
            Specialization: 'تخصص الذكاء الاصطناعي والتعلم الآلي:',
            SpecializationTitle: 'يغطي تخصصنا في الذكاء الاصطناعي والتعلم الآلي مجموعة واسعة من الخدمات ، المصممة لتلبية الاحتياجات المتنوعة للشركات في المشهد القائم على البيانات اليوم:',
            dec: `نحن نسخر قوة الذكاء الاصطناعي والتعلم الآلي لتطوير حلول ذكية قائمة على البيانات تعمل على تحويل الأعمال. تتيح لنا خبرتنا في معالجة اللغة الطبيعية ورؤية الكمبيوتر والتحليلات التنبؤية وأنظمة التوصية تقديم حلول تعمل على تحسين العمليات وتعزيز صنع القرار وتوفير تجارب مخصصة للمستخدمين.`,
            SpecializationList: [
                {
                    title:  `  التحليلات التنبؤية: `,
                    dec:  `نقوم بتطوير نماذج تنبؤية لتحليل البيانات التاريخية للتنبؤ بالاتجاهات المستقبلية ، وتمكين الشركات من توقع سلوك العملاء وأنماط الطلب وتحولات السوق.`
                }, 
                {
                    title:  `معالجة اللغة الطبيعية (NLP):`,
                    dec:  `تعمل حلول البرمجة اللغوية العصبية الخاصة بنا على تمكين الشركات من استخراج الأفكار من البيانات النصية ، وتمكين تحليل المشاعر ، وترجمة اللغة ، وروبوتات المحادثة ، والمزيد.`
                }, 
                {
                    title:  `رؤية الكمبيوتر: `,
                    dec:  `نقوم بإنشاء نماذج رؤية حاسوبية متقدمة تمكن الآلات من تفسير وفهم المعلومات المرئية ، وإحداث ثورة في الصناعات مثل الرعاية الصحية والتصنيع وتجارة التجزئة.`
                }, 
                {
                    title:  `أنظمة التوصيات: `,
                    dec:  `تحلل خوارزميات التوصية الخاصة بنا سلوك المستخدم لتقديم توصيات مخصصة ، وتعزيز تجارب المستخدم وقيادة المشاركة.`
                }, 
                {
                    title:  `  كشف الشذوذ: `,
                    dec:  `نحن نصمم أنظمة ذكاء اصطناعي يمكنها تحديد الانحرافات والقيم المتطرفة داخل البيانات ، وتمكين الشركات من اكتشاف الاحتيال ، وتعطل المعدات ، والمخالفات الأخرى.`
                }, 
                {
                    title:  `  التحسين  : `,
                    dec: `.  تعمل خوارزميات التحسين الخاصة بنا على ضبط العمليات والموارد والعمليات بأقصى قدر من الكفاءة وتوفير التكاليف`
                 }, 
            ],
            SkillsList: [
                {
                    title:  `خبرة التعلم العميق:`,
                    dec:  `يتفوق فريقنا في تقنيات التعلم العميق ، بما في ذلك الشبكات العصبية التلافيفية (CNN) والشبكات العصبية المتكررة (RNN) والمحولات ، مما يمكننا من حل المشكلات المعقدة في التعرف على الصور ومعالجة اللغة الطبيعية والمزيد.`
                }, 
                {
                    title:  `خبرة البيانات: `,
                    dec:  `نمتلك مهارات هندسية قوية للمعالجة المسبقة للبيانات وتحويلها إلى تنسيقات مناسبة للتدريب ونشر نماذج الذكاء الاصطناعي.`
                }, 
                {
                    title:  `تطوير الخوارزميات: `,
                    dec:  `يتمتع خبراؤنا بالمهارة في تطوير وضبط الخوارزميات التي تعالج تحديات عمل محددة ، مما يضمن الأداء الأمثل للنموذج.`
                }, 
                {
                    title:  ` تقييم النموذج والتحقق من صحته:` ,
                    dec:  `تعتبر عمليات الاختبار والتحقق الصارمة جزءًا لا يتجزأ من نهجنا ، مما يضمن أن نماذج الذكاء الاصطناعي تعمل بشكل دقيق وموثوق.`
                }, 
                {
                    title:  `التعاون متعدد التخصصات: `,
                    dec:  `يتعاون متخصصو الذكاء الاصطناعي لدينا بشكل وثيق مع خبراء المجال لضمان توافق حلولنا مع المتطلبات والأهداف الخاصة بالصناعة.`
                },
                {
                    title:  `الذكاء الاصطناعي الأخلاقي`,
                    dec:  ` نعطي الأولوية للاعتبارات الأخلاقية في الذكاء الاصطناعي والتعلم الآلي ، مما يضمن العدالة والشفافية والمساءلة في حلولنا.`
                }, 
                {
                    title:  `التعلم المستمر`,
                    dec:  `مجال الذكاء الاصطناعي في تطور مستمر . نبقى على اطلاع بأحدث الأبحاث والتطورات لتقديم أحدث الحلول وأكثرها فعالية لعملائنا.`
                }, 
            ],
            SkillsTitle: ' المهارات التي تميزنا:    ',
            SkillsDec:  `في Vigtas ، نعتقد أن الذكاء الاصطناعي والتعلم الآلي يمتلكان القدرة على إعادة تشكيل الصناعات ودفع نمو غير مسبوق. مهمتنا هي مساعدة الشركات على تسخير هذه الإمكانات من خلال توفير حلول مبتكرة قائمة على البيانات تعزز اتخاذ القرار وتبسيط العمليات وتعزيز الابتكار. انضم إلينا في رحلة احتضان المستقبل مع الذكاء الاصطناعي والتعلم الآلي كميزة تنافسية لك.`
   

        },

        Blockchain: {
            Specialization: 'تخصص تطوير Blockchain:',
            SpecializationTitle: 'يشمل تخصصنا في تطوير blockchain مجموعة واسعة من الخدمات التي تلبي الاحتياجات الفريدة للشركات التي تسعى إلى تسخير قوة التقنيات اللامركزية:',
            title: 'بلوكشين',
            dec: `بصفتنا روادًا في تقنية blockchain ، فإننا نبني تطبيقات آمنة وشفافة ولامركزية تحدث ثورة في الصناعات. تشتمل حلول blockchain الخاصة بنا على العقود الذكية وأنظمة إدارة سلسلة التوريد والمنصات القائمة على الرموز ، مما يوفر أمانًا محسنًا وثقة في العمليات التجارية.`,
      
            SpecializationList: [
                {
                    title:  `  تطوير العقود الذكية :`,
                    dec:  `نقوم بإنشاء عقود ذكية قوية وآمنة تعمل على أتمتة العمليات وتنفيذ الاتفاقيات وضمان التفاعلات غير الموثوق بها ، كل ذلك مع تقليل مخاطر الاحتيال أو التلاعب.`
                 },  
                 {
                    title:  ` التطبيقات اللامركزية ( DApps ): `,
                    dec:  `يقوم فريقنا بتصميم وتطوير DApps التي تستفيد من الطبيعة اللامركزية لـ blockchain لتوفير أمان وخصوصية وتحكم محسّن للمستخدمين.`
                 },  
                 {
                    title:  ` بلوكشين خاص وكونسورتيوم:`,
                    dec:  `نحن نبني حلول blockchain خاصة واتحادات تتيح للشركات التعاون بأمان وشفافية داخل الشبكات المغلقة.`
                 },  
                 {
                    title:  `حلول سلسلة التوريد:  `,
                    dec:  `نحن نطبق حلول blockchain لتتبع والتحقق من حركة البضائع في جميع أنحاء سلسلة التوريد ، وتعزيز إمكانية التتبع والأصالة والكفاءة.`
                 },  
                 {
                    title:  `أنظمة الهوية الرقمية:  `,
                    dec:  `يقوم خبراؤنا بتصميم أنظمة هوية قائمة على blockchain توفر للمستخدمين التحكم في معلوماتهم الشخصية ، مما يقلل من مخاطر سرقة الهوية.`
                 },  
                 {
                    title:  ` الترميز وإدارة الأصول: `,
                    dec:  `نقوم بتطوير حلول الترميز التي تمكن من تمثيل الأصول في العالم الحقيقي كرموز رقمية ، مما يسهل الملكية والتجارة والملكية الجزئية.`
                 },  
            ],
            SkillsList: [ 
                {
                    title:  ` بروتوكولات Blockchain: `,
                    dec:  `فريقنا على دراية جيدة ببروتوكولات blockchain المختلفة مثل Ethereum و Hyperledger و Corda والمزيد ، مما يسمح لنا باختيار الإطار المناسب لمتطلبات كل مشروع.`
                 },  
                 {
                    title:  `تطوير العقود الذكية:  `,
                    dec:  `نحن نمتلك خبرة عميقة في لغات البرمجة مثل Solidity واللغات المتوافقة مع Hyperledger Fabric لإنشاء عقود ذكية فعالة وآمنة.`
                 },  
                 {
                    title:  `تقنية دفتر الأستاذ الموزع:  `,
                    dec:  `يفهم خبراؤنا هندسة وميكانيكا دفاتر الأستاذ الموزعة ، مما يضمن التكامل السلس لـ blockchain في عمليات عملك.`
                 },  
                 {
                    title:  `الأمان والتشفير:  `,
                    dec:  `نعطي الأولوية للأمان في جميع جوانب تطوير blockchain ، ونستخدم التشفير والتجزئة وتقنيات التشفير الأخرى لحماية البيانات والمعاملات.`
                 },  
                 {
                    title:  `قابلية التشغيل البيني:  `,
                    dec:  ` نحن متخصصون في إنشاء حلول blockchain التي يمكن أن تتكامل بسلاسة مع الأنظمة والشبكات الحالية ، مما يتيح عمليات انتقال أكثر سلاسة إلى الأنظمة الأساسية اللامركزية.    `
                 },  
                 {
                    title:  ` معرفة مجال الأعمال: `,
                    dec:  `يتعاون فريقنا بشكل وثيق مع العملاء لفهم التحديات والأهداف الخاصة بالصناعة ، وتصميم حلول blockchain التي تلبي احتياجات العمل الفريدة.`
                 },  
            ],
            SkillsTitle: ' المهارات التي تميزنا:    ',
            SkillsDec:  `يتطور مجال blockchain بسرعة. نبقى في الطليعة من خلال البحث المستمر وتجريب تقنيات واتجاهات blockchain الناشئة.

            Vigtas مكرس لإحداث ثورة في الصناعات من خلال حلول blockchain التي تضخم الأمن والشفافية والكفاءة. مهمتنا هي تمكين الشركات من احتضان المستقبل اللامركزي ، وفتح فرص جديدة للنمو والابتكار والتعاون. انضم إلينا في تشكيل عالم تكون فيه blockchain أكثر من مجرد تقنية - إنها محفز للتغيير التحويلي.
            `

        },
        ContactUS: 'هل تفكر في بدء مشروع جديد ؟',
        ContactUSTitle: 'اتصل بنا',
        ContactUSBtn: 'اتصل الآن'

    },
    "en": {
        titleVision: 'Our Vision',
        titleMission: 'Our Mission',
        Vision: 'Our vision is to lead the charge in digital transformation by developing pioneering solutions that shape the future. We envision a world where our expertise in Web and Mobile Developments, AI, Blockchain, Machine Learning, and Mobile App Management empowers businesses across industries to embrace innovation, optimize operations, and create lasting value. By fostering a culture of continuous learning and adaptability, we aim to be at the forefront of emerging technologies, driving positive change for our clients and society at large.',
        Mission: `At Vigtas, our mission is to empower our clients with cutting-edge technology solutions that drive growth, efficiency, and sustainability. We aim to be a trusted partner, leveraging our expertise to create transformative experiences that propel businesses to new heights. Through our dedication to innovation, collaboration, and customer satisfaction, we strive to build lasting relationships that make a positive impact on our clients' success.`,
        title: 'Our Services',
        dec: ' Welcome to Vigtas, a leading software development company at the forefront of the digital revolution. With a passion for innovation and a commitment to excellence, we specialize in Web and Mobile Developments, AI, Blockchain, Machine Learning, and Mobile App Management solutions. Since our inception, we have been empowering businesses and organizations to harness the power of technology and stay ahead in an ever-evolving digital landscape.     '
        , Service1: 'Web and Mobile Developments'
        , Service2: 'Mobile App Management '
        , Service3: 'AI and Machine Learning '
        , Service4: 'Blockchain',
        WebApp: {
            title: 'Web and Mobile Developments',
            dec: `At Vigtas, we take pride in creating cutting-edge web and mobile applications that blend seamless functionality with stunning design. Our team of skilled developers, designers, and user experience experts work collaboratively to deliver intuitive, responsive, and user-friendly applications. Whether it's a website, e-commerce platform, or mobile app, we ensure that our solutions exceed our clients' expectations and enhance their online presence.`,
        },

        Mobile: {
            title: 'Mobile App Management',
            Specialization: 'Mobile App Management Specialization:',
            SpecializationTitle: `At Vigtas, we recognize that the success of a mobile app extends beyond its initial development. Our specialized mobile app management services encompass every stage of an app's lifecycle:            `,
            dec: `Our mobile app management services ensure that our clients' applications operate at peak performance and remain up-to-date with the latest technologies. From app updates and performance optimization to security enhancements, we manage the complete lifecycle of mobile applications, allowing our clients to focus on their core business objectives.`,
            SpecializationList: [
                {
                    title: 'App Deployment and Distribution :',
                    dec: 'We ensure seamless app distribution across various app stores, ensuring compliance with guidelines and optimizing app discoverability.'
                },
                {
                    title: `Performance Monitoring :`,
                    dec: ` Our monitoring solutions provide real-time insights into app performance, allowing for proactive identification and resolution of performance issues to deliver an exceptional user experience.`
                },

                {
                    title: `App Security:`,
                    dec: `Security is paramount. We implement robust security measures, including encryption, authentication, and authorization, to safeguard user data and app functionality.                    `
                },
                {
                    title: `App Updates and Maintenance : `,
                    dec: `Our team handles regular updates, bug fixes, and improvements to keep your app current and functional, enhancing user engagement and satisfaction.`
                },
                {
                    title: `User Analytics : `,
                    dec: ` We integrate analytics tools to help you gather insights into user behavior, preferences, and interactions, enabling data-driven decisions for app enhancements.`
                },
                {
                    title: `Crash Reporting and Debugging :`,
                    dec: `Our solutions include crash reporting tools that pinpoint app crashes and bugs, enabling swift debugging and minimizing user disruptions.`
                },
                {
                    title: `Data Backup and Recovery : `,
                    dec: `We implement data backup and recovery strategies to ensure minimal data loss in case of unforeseen events, assuring business continuity.`
                },
            ],
            SkillsList: [
                {
                    title: `Mobile App Lifecycle Expertise : `,
                    dec: `Our team possesses a deep understanding of the complete app lifecycle, from ideation to post-launch management, allowing us to provide comprehensive and effective solutions.`
                },
                {
                    title: `Technical Proficiency : `,
                    dec: `We are proficient in mobile app development languages, frameworks, and tools, enabling us to manage apps across various platforms and technologies.`
                },
                {
                    title: `Innovative Solutions :`,
                    dec: ` Our commitment to innovation drives us to adopt the latest technologies, ensuring that our clients benefit from the most advanced mobile app management practices.`
                },
                {
                    title: `Problem Solving :`,
                    dec: `Complex app management challenges require creative problem-solving. We excel in identifying issues, diagnosing root causes, and devising effective remedies.`
                },
                {
                    title: `Adaptability : `,
                    dec: `The mobile landscape is ever-evolving. We stay adaptable and agile, adjusting our strategies to meet changing trends and technologies`
                },
                {
                    title: `Collaboration :`,
                    dec: ` We work closely with clients, understanding their business goals and translating them into mobile app management strategies that drive success.`
                },
                {
                    title: `User-Centric Focus: `,
                    dec: `We prioritize the end-users' experience, aiming to provide apps that are not only functional but also delightful and user-friendly.`
                },
            ],
            SkillsTitle: 'Our Skills',
            SkillsDec: `At Vigtas, we recognize that mobile app management is an ongoing journey, and we are committed to guiding you through every step. Our mission is to optimize your app's performance, security, and user satisfaction while allowing you to focus on your core business objectives. Join us in elevating your mobile app management practices to new heights.`

        },

        AI: {
            title: 'AI and Machine Learning',
            Specialization: 'AI and Machine Learning Specialization:            ',
            SpecializationTitle: `Our specialization in AI and Machine Learning covers a wide spectrum of services, designed to meet the diverse needs of businesses in today's data-driven landscape:`,
            dec: 'We harness the power of Artificial Intelligence and Machine Learning to develop intelligent, data-driven solutions that transform businesses. Our expertise in natural language processing, computer vision, predictive analytics, and recommendation systems allows us to deliver solutions that optimize processes, enhance decision-making, and provide personalized experiences to users.',
            SpecializationList: [
                {
                    title:  `Predictive Analytics :`,
                    dec:  `We develop predictive models that analyze historical data to forecast future trends, enabling businesses to anticipate customer behavior, demand patterns, and market shifts.`
                }, 
                {
                    title:  `Natural Language Processing (NLP):`,
                    dec:  `Our NLP solutions empower businesses to extract insights from textual data, enabling sentiment analysis, language translation, chatbots, and more.`
                }, 
                {
                    title:  `Computer Vision : `,
                    dec:  `Security is paramount. We implement robust security measures, including encryption, authentication, and authorization, to safeguard user data and app functionality.`
                }, 
                {
                    title:  `Recommendation Systems : `,
                    dec:  `Our recommendation algorithms analyze user behavior to provide personalized recommendations, enhancing user experiences and driving engagement.`
                }, 
                {
                    title:  `Anomaly Detection : `,
                    dec:  `We design AI systems that can identify anomalies and outliers within data, enabling businesses to detect fraud, equipment failures, and other irregularities.                    `
                }, 
                {
                    title:  `Optimization   : `,
                    dec: `Our optimization algorithms fine-tune processes, resources, and operations for maximum efficiency and cost savings.`
                 }, 
            ],
            SkillsList: [
                {
                    title:  `Deep Learning Expertise :`,
                    dec:  `Our team excels in deep learning techniques, including convolutional neural networks (CNNs), recurrent neural networks (RNNs), and transformers, enabling us to solve complex problems in image recognition, natural language processing, and more.`
                }, 
                {
                    title:  `Data Expertise :`,
                    dec:  `We possess strong data engineering skills to preprocess and transform data into formats suitable for training and deploying AI models.`
                }, 
                {
                    title:  `Algorithm Development : `,
                    dec:  `Our experts are skilled in developing and fine-tuning algorithms that address specific business challenges, ensuring optimal model performance.`
                }, 
                {
                    title:  `Model Evaluation and Validation : `,
                    dec:  ` Rigorous testing and validation processes are integral to our approach, ensuring that AI models perform accurately and reliably.`
                }, 
                {
                    title:  `Interdisciplinary Collaboration : `,
                    dec:  ` Our AI specialists collaborate closely with domain experts to ensure that our solutions align with industry-specific requirements and objectives.`
                },
                {
                    title:  `Ethical AI: `,
                    dec:  ` We prioritize ethical considerations in AI and Machine Learning, ensuring fairness, transparency, and accountability in our solutions.`
                }, 
                {
                    title:  `Continuous Learning: `,
                    dec:  `The field of AI is ever-evolving. We stay updated with the latest research and advancements to bring the most current and effective solutions to our clients.`
                }, 
            ],
            SkillsTitle: 'Our Skills',
            SkillsDec: `At Vigtas, we believe that AI and Machine Learning hold the potential to reshape industries and drive unprecedented growth. Our mission is to help businesses harness this potential by providing innovative, data-driven solutions that enhance decision-making, streamline operations, and foster innovation. Join us on the journey to embrace the future with AI and Machine Learning as your competitive advantage.`
  
        },

        Blockchain: {
            title: 'Blockchain',
            Specialization: 'Blockchain Development  Specialization:            ',
            SpecializationTitle: `Our specialization in blockchain development encompasses a wide array of services that cater to the unique needs of businesses seeking to harness the power of decentralized technologies:`,
            dec: `As pioneers in blockchain technology, we build secure, transparent, and decentralized applications that revolutionize industries. Our blockchain solutions encompass smart contracts, supply chain management systems, and token-based platforms, providing enhanced security and trust in business processes.`,
            SpecializationList: [
                {
                    title:  ` Smart Contract Development : `,
                    dec:  ` We create robust and secure smart contracts that automate processes, execute agreements, and ensure trustless interactions, all while minimizing the risk of fraud or manipulation.`
                 },  
                 {
                    title:  `Decentralized Applications (DApps) : `,
                    dec:  ` We build private and consortium blockchain solutions that allow businesses to collaborate securely and transparently within closed networks.`
                 },  
                 {
                    title:  `Private and Consortium Blockchains :  `,
                    dec:  ` We build private and consortium blockchain solutions that allow businesses to collaborate securely and transparently within closed networks.`
                 },  
                 {
                    title:  `Supply Chain Solutions : `,
                    dec:  `We implement blockchain solutions to track and verify the movement of goods throughout the supply chain, enhancing traceability, authenticity, and efficiency.`
                 },  
                 {
                    title:  `Digital Identity Systems :  `,
                    dec:  `Our experts design blockchain-based identity systems that provide users with control over their personal information, reducing identity theft risks.`
                 },  
                 {
                    title:  `Tokenization and Asset Management : `,
                    dec:  `We develop tokenization solutions that enable the representation of real-world assets as digital tokens, facilitating ownership, trading, and fractional ownership.`
                 },  
            ],
            SkillsList: [ 
                {
                    title:  `Blockchain Protocols : `,
                    dec:  `Our team is well-versed in various blockchain protocols such as Ethereum, Hyperledger, Corda, and more, allowing us to choose the right framework for each project's requirements.`
                 },  
                 {
                    title:  `Smart Contract Development : `,
                    dec:  `We possess deep expertise in programming languages like Solidity and languages compatible with Hyperledger Fabric for creating efficient and secure smart contracts`
                 },  
                 {
                    title:  `Distributed Ledger Technology :`,
                    dec:  ` Our experts understand the architecture and mechanics of distributed ledgers, ensuring the seamless integration of blockchain into your business processes.`
                 },  
                 {
                    title:  `Security and Cryptography :`,
                    dec:  `We prioritize security in all aspects of blockchain development, employing encryption, hashing, and other cryptographic techniques to safeguard data and transactions.`
                 },  
                 {
                    title:  ` Interoperability :`,
                    dec:  ` We specialize in creating blockchain solutions that can seamlessly integrate with existing systems and networks, enabling smoother transitions to decentralized platforms.`
                 },  
                 {
                    title:  `Business Domain Knowledge :`,
                    dec:  ` Our team collaborates closely with clients to understand their industry-specific challenges and objectives, tailoring blockchain solutions that address unique business needs.`
                 }, 
                 {
                    title:  `Continuous Learning :`,
                    dec:  `The field of blockchain evolves rapidly. We stay at the forefront by continuously researching and experimenting with emerging blockchain technologies and trends.`
                  },  
            ],
            SkillsTitle: ' Our Skills   ',
            SkillsDec:  `Vigtas is dedicated to revolutionizing industries through blockchain solutions that amplify security, transparency, and efficiency. Our mission is to empower businesses to embrace the decentralized future, unlocking new opportunities for growth, innovation, and collaboration. Join us in shaping a world where blockchain is more than a technology—it's a catalyst for transformative change.`  
        },
        ContactUS: 'Have a project in mind? Let’s get to work.',
        ContactUSTitle: 'Contact Us',
        ContactUSBtn: 'Contact Now'
    }
}

export default Translate