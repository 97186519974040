import React, { useContext, useEffect } from 'react'
import Component from '../../constants/Component'
import img from '../../assets/Img'
import './style.scss'
import { Col, Row } from 'react-bootstrap'
import { LocalizationContext } from '../../context/LangChange'
import Translate from './Translate'
import AOS from 'aos';

const About = () => {
  let { isLang } = useContext(LocalizationContext);
 
  window.scroll(0, 0)
  useEffect(() => {
    AOS.init({
      duration: '1500'
    });
  }, []);
  return (

    <div className="overflow-hidden">

      <Component.HeaderBase title={Translate[isLang]?.title} />
      <Row className='m-auto text-center d-flex justify-content-center align-items-center mt-8'>
        <Col xl={8} lg={6} md={9} sm={11}>
          <div className="title">
            <h2 className="sect-title text-center" data-aos="flip-left"  >  {Translate[isLang]?.title} </h2>
            <p data-aos="fade-up" className="aos-init aos-animate" dir={isLang==="en"?'ltr':'rtl'}> {Translate[isLang]?.dec}</p>
          </div>
        </Col>
      </Row>
      <section className="about-techy about-techy-4d  pt-lg-60 pb-lg-15">

        {<img className="about-shape-4d shape-1b d-none d-xl-inline-block" src={img.star1} alt="shape" />}
        {<img className="about-shape-4d shape-2b d-none d-lg-inline-block" src={img.star1} alt="shape" />}
        {<img className="about-shape-4d shape-3b d-none d-md-inline-block" src={img.star3} alt="shape" />}
        {<img className="about-shape-4d shape-4b d-none d-md-inline-block" src={img.star2} alt="shape" />}
        {<img className="about-shape-4d shape-5b d-none d-xl-inline-block" src={img.star2} alt="shape" />}
        <div className="container" dir={isLang === "en" ? 'ltr' : 'rtl'}>
          <div className="row gx-4 gx-xxl-5 align-items-center">
            <div className="col-lg-7 aos-init aos-animate" data-aos="fade-right" data-aos-delay="100">
              <div className="about-img-wrapper-four position-relative mb-45">
                <img className="main-img" src={img.AboutMession} alt="img" />
                {/* <img className="main-img-two d-none d-xl-inline-block" src={img.AboutUS2} alt="img1" /> */}
                <img className="shape-one" src={img.AnimationLine} alt="shape2" />
              </div>
            </div>
            <div className="col-lg-5 aos-init aos-animate" data-aos="fade-left" data-aos-delay="100">
              <div className="block-style-one pl-lg-20 mb-45">
                <h1 className=" " style={{ marginBottom: '15px' }}>  {Translate[isLang]?.titleMission} </h1>
                <p  dir={isLang==="en"?'ltr':'rtl'}>{Translate[isLang]?.Mission}</p>
              </div>
            </div>
          </div>
        </div>
      </section>



      <section className="about-techy about-techy-4d  pt-lg-60 pb-lg-15" >


        {<img className="about-shape-4d shape-1b d-none d-xl-inline-block" src={img.star1} alt="shape" />}
        {<img className="about-shape-4d shape-2b d-none d-lg-inline-block" src={img.star1} alt="shape" />}
        {<img className="about-shape-4d shape-3b d-none d-md-inline-block" src={img.star3} alt="shape" />}
        {<img className="about-shape-4d shape-4b d-none d-md-inline-block" src={img.star2} alt="shape" />}
        {<img className="about-shape-4d shape-5b d-none d-xl-inline-block" src={img.star2} alt="shape" />}
        <div className="container" dir={isLang === "en" ? 'ltr' : 'rtl'}>
          <div className="row gx-4 gx-xxl-5 align-items-center">
            <div className="col-lg-7 aos-init aos-animate" data-aos="fade-right" data-aos-delay="100">
              <div className="about-img-wrapper-four position-relative mb-45">
                <img className="main-img" style={{ borderRight: '8px' }} src={img.AboutVision} alt="img" />
                {<img className="shape-one" src={img.AnimationLine} alt="shape2" />}
              </div>
            </div>
            <div className="col-lg-5 aos-init aos-animate" data-aos="fade-left" data-aos-delay="100">
              <div className="block-style-one pl-lg-20 mb-45">
                <h1 className=" " style={{ marginBottom: '15px' }}> {Translate[isLang]?.titleVision}  </h1>
                <p  dir={isLang==="en"?'ltr':'rtl'}>{Translate[isLang]?.Vision}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default About
