import React, { useContext, useEffect } from 'react'
import './style.scss'
import img from '../../assets/Img'
import Icons from '../../constants/Icons'
import Component from '../../constants/Component'
import { motion } from 'framer-motion';
import Translate from './Translate'
import { LocalizationContext } from '../../context/LangChange'

const Contact = () => {
  let { isLang } = useContext(LocalizationContext);

  useEffect(() => {
    window.scroll(0, 0)
  }, [])
  return (
    <>
      <Component.HeaderBase /* title={'Contact Us '}  */ title={ Translate[isLang]?.title} />
      <div className="contact-section pt-145 pb-100 pt-lg-60 pb-lg-15">
        <div className="container">
          <div className="row gx-4 gx-xxl-5" dir={isLang == "en" ? 'ltr' : 'rtl'}>
            <div className="col-lg-5">
              <div className="contact-info-wrapper me-xxl-5 pe-xxl-5 mb-45">
                <h2 className="sect-title mb-5">{ Translate[isLang]?.conatctList}</h2>
                <div className="single-info-box mb-50" style={{ marginBottom: '50px' }}>
                  <div className="icon">
                    <Icons.Call color={'#ffff'} />
                  </div>
                  <span className="mt-5" ><a dir='ltr' className='text-light' href="tel:+201090105214" target="_blank" rel="noreferrer"  > +20 109 010 5214 </a></span>
                </div>
                <div className="single-info-box mb-50" style={{ marginBottom: '50px' }}>
                  <div className="icon">
                    <Icons.Location color={'#ffff'} />
                  </div>
                  <span className="mt-4 px-5">  { Translate[isLang]?.location} </span>
                  <span className="mt-1 px-5">  { Translate[isLang]?.location1} </span>
                </div>
                <div className="single-info-box mb-50" style={{ marginBottom: '50px' }}>
                  <div className="icon">
                    <Icons.Email2 color={'#ffff'} />
                  </div>
                  <span className="mt-4">
                    <a href="mailto:info@vigtas.com" className='text-light' target="_blank" rel="noreferrer" >info@vigtas.com</a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-7  ">  
                    <motion.div
                      className="gmap_canvas shadow"
                      whileInView={{ scale: [0, 1] }}
                      transition={{ duration: 1, type: 'tween' }}
                    >
                    <iframe
                      className="gmap_iframe"
                      width="100%"
                      height="94.5%"
                      title="خريطة الموقع"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3452.914373893541!2d31.344594999999998!3d30.0679888!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583e0d3bf51a57%3A0x6b42d112a2cfede4!2s3%20Makram%20Ebeid%2C%20Al%20Manteqah%20as%20Sadesah%2C%20Nasr%20City%2C%20Cairo%20Governorate%204450435!5e0!3m2!1sen!2seg!4v1692286526299!5m2!1sen!2seg" 
                      // "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3453.377652490315!2d31.3272457!3d30.0547076!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583e5eeb3480b1%3A0x45a396fb9502b6ee!2s90%20El%20Tayaran%20St%2C%20Manteqet%20Al%20Cinema%2C%20Nasr%20City%2C%20Cairo%20Governorate%204450101!5e0!3m2!1sen!2seg!4v1691014623399!5m2!1sen!2seg"
                    />

                     </motion.div>
                <div className='map-contactus    pb-40 mb-xxl-4' >
                  <div className="mapouter ">
                  </div>
                </div> 
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact
