
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './style/App.scss';
import Component from './constants/Component';
import LangChange from './context/LangChange';

function App() {
  const router = createBrowserRouter([
    {
      path: '/', element: <Component.Vigtas />, children: [
        { index: true, element: <Component.Home /> },
        {
          path: '/services', children: [
            { index: true, element: <Component.Service /> },
            { path:':type', element: <Component.Details /> },
          ]
        },
        { path: '/about', element: <Component.About /> },
        { path: '/portfolio', element: <Component.About /> },
        { path: '/contact', element: <Component.Contact /> },
        { path: '*', element: <h1>Page Notfound</h1> },
      ]
    }

  ])
  return (
    <>
      <LangChange>
        <RouterProvider router={router} />
      </LangChange>
    </>
  );
}

export default App;
