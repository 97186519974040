let Translate = {
    "ar": {
        title: 'اتصل بنا  ',
        conatctList: 'دعونا نتواصل معنا    ',
        location:'  مصر , القاهرة ',
        dec: 'مرحبًا بكم في Vigtas ، شركة تطوير برمجيات رائدة في طليعة الثورة الرقمية. من خلال شغفنا بالابتكار والالتزام بالتميز ، نحن متخصصون في تطوير الويب والجوال ، والذكاء الاصطناعي ، و Blockchain ، والتعلم الآلي ، وحلول إدارة تطبيقات الأجهزة المحمولة. منذ بدايتنا ، قمنا بتمكين الشركات والمؤسسات لتسخير قوة التكنولوجيا والبقاء في الطليعة في مشهد رقمي دائم التطور.'
    },
    "en": {
        title: '  Contact Us',
        location:' 3 Makram Ebeid - Nasr City  ', 
        location1:'Cairo , Egypt',
        conatctList: 'Let’s Get Connect With Us         ',
        dec: ' Welcome to Vigtas, a leading software development company at the forefront of the digital revolution. With a passion for innovation and a commitment to excellence, we specialize in Web and Mobile Developments, AI, Blockchain, Machine Learning, and Mobile App Management solutions. Since our inception, we have been empowering businesses and organizations to harness the power of technology and stay ahead in an ever-evolving digital landscape.     '
    }
}

export default Translate