let Translate = {
    "ar": {
        navbarTop: {
            time: ' مواعيد العمل ',
            timeDetails: '10:00 ص - 6:00 م ',
            location: 'موقع الشركه',
            locationDetails: 'مصر , القاهرة',
            phone: 'رقم الهاتف /   واتساب',
            location2:'3 مكرم عبيد - مدينة نصر  ', 
            location1:' القاهرة، مصر ',
        },

        Sidebar: {
            title: 'من نحن ',
            dec: 'مرحبًا بكم في Vigtas ، شركة تطوير برمجيات رائدة في طليعة الثورة الرقمية. من خلال شغفنا بالابتكار والالتزام بالتميز ',
            ContactUS: 'معلومات الاتصال',
        },
        footer: {
            Quick: '   الصفحـــات ',
            home: 'الصفحة الرئيسية',
            about: 'معلومات عنا',
            services: 'خدماتنا',
            contact: 'اتصل بنا '
        }


    },
    "en": {
        navbarTop: {
            time: 'Time',
            timeDetails: ' 10:00 AM - 6:00 PM ',
            location: 'Location',
            locationDetails: 'Egypt , Cairo  ',
            location2:' 3 Makram Ebeid - Nasr City  ', 
            location1:'Cairo , Egypt',
            phone: ' Mobile / WhatsApp  '
        },

        Sidebar: {
            title: 'About us   ',
            dec: 'Welcome to Vigtas, a leading software development company at the forefront of the digital revolution.',
            ContactUS: 'Contact Info'
        },
        footer: {
            Quick: 'Quick Links',
            home: 'Home',
            about: 'About',
            services: 'Services',
            contact: 'Contact '
        }
    }
}

export default Translate