import AOS from 'aos';
import React, { useContext, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import img from '../../assets/Img';
import Icons from '../../constants/Icons';
import { LocalizationContext } from '../../context/LangChange';
import Translate from './Translate'

const Services = () => {
    let { isLang } = useContext(LocalizationContext);


    const dataService = [
        {
            path: 'mobile',
            icons: <Icons.Service5 />,
            img: img.Icons11,
            titleAr: 'تطوير الويب والجوال',
            descriptionAr: '  في Vigtas، نفخر بإنشاء تطبيقات الويب والجوال المتطورة التي تمزج بين الوظائف السلسة والتصميم المذهل. يعمل فريقنا من المطورين والمصممين وخبراء تجربة المستخدم المهرة بشكل تعاوني لتقديم تطبيقات بديهية وسريعة الاستجابة وسهلة الاستخدام. سواء أكان موقعًا إلكترونيًا أو منصة تجارة إلكترونية أو تطبيقًا للهاتف المحمول ، فإننا نضمن أن تتجاوز حلولنا توقعات عملائنا وتعزز تواجدهم عبر الإنترنت. ',
             title: 'Web & Mobile  Developments',
            description: `At Vigtas, we take pride in creating cutting-edge web and mobile applications that blend seamless functionality with stunning design. Our team of skilled developers, designers, and user experience experts work collaboratively to deliver intuitive, responsive, and user-friendly applications. Whether it's a website, e-commerce platform, or mobile app, we ensure that our solutions exceed our clients' expectations and enhance their online presence.`
        },
        {
            path: 'management',
            icons: <Icons.Service5 />,
            img: img.Icons13,
            titleAr: 'إدارة تطبيقات الجوال',
            descriptionAr: 'تضمن خدمات إدارة تطبيقات الأجهزة المحمولة لدينا أن تطبيقات عملائنا تعمل بأعلى أداء وأن تظل مواكبة لأحدث التقنيات. من تحديثات التطبيقات وتحسين الأداء إلى تحسينات الأمان ، ندير دورة الحياة الكاملة لتطبيقات الأجهزة المحمولة ، مما يسمح لعملائنا بالتركيز على أهداف أعمالهم الأساسية.',
            title: 'Mobile App Management ',
            description: `Our mobile app management services ensure that our clients' applications operate at peak performance and remain up-to-date with the latest technologies. From app updates and performance optimization to security enhancements, we manage the complete lifecycle of mobile applications, allowing our clients to focus on their core business objectives.`
        },
        {
            path: 'ai',
            icons: <Icons.Service5 />,
            img: img.Icons10,
            titleAr: 'الذكاء الاصطناعي والتعلم الآلي',
            descriptionAr: 'نحن نسخر قوة الذكاء الاصطناعي والتعلم الآلي لتطوير حلول ذكية قائمة على البيانات تعمل على تحويل الأعمال. تتيح لنا خبرتنا في معالجة اللغة الطبيعية ورؤية الكمبيوتر والتحليلات التنبؤية وأنظمة التوصية تقديم حلول تعمل على تحسين العمليات وتعزيز صنع القرار وتوفير تجارب مخصصة للمستخدمين.',
            title: 'AI and Machine Learning',
            description: 'We harness the power of Artificial Intelligence and Machine Learning to develop intelligent, data-driven solutions that transform businesses. Our expertise in natural language processing, computer vision, predictive analytics, and recommendation systems allows us to deliver solutions that optimize processes, enhance decision-making, and provide personalized experiences to users.'
        },
        {
            path: 'blockchain',
            icons: <Icons.Service5 />,
            img: img.Icons12,
            title: 'Blockchain',
            titleAr: 'بلوكشين',
            descriptionAr: 'بصفتنا روادًا في تقنية blockchain ، فإننا نبني تطبيقات آمنة وشفافة ولامركزية تحدث ثورة في الصناعات. تشتمل حلول blockchain الخاصة بنا على العقود الذكية وأنظمة إدارة سلسلة التوريد والمنصات القائمة على الرموز ، مما يوفر أمانًا محسنًا وثقة في العمليات التجارية.',
            description: 'As pioneers in blockchain technology, we build secure, transparent, and decentralized applications that revolutionize industries. Our blockchain solutions encompass smart contracts, supply chain management systems, and token-based platforms, providing enhanced security and trust in business processes.'
        },
    ]

    useEffect(() => {
        AOS.init({
            duration: '1500'
        });
    }, []);
    return (
        <>
            <div className="app__service">
                <div className="gradient-bg our-services service-bg-three pt-95 pb-55 pt-lg-55 pb-lg-15">
                    <img className="service-shape-3c c-100" src={img.AnimationLine} alt="shape" />
                </div>
                <Container>

                    <Row className='m-auto text-center d-flex justify-content-center align-items-center'>
                        <Col xl={11} lg={6} md={12} sm={12}>
                            <div className="title">
                                <h2 className="sect-title text-center" data-aos="flip-left"
                                    data-aos-anchor-placement="bottom-bottom">{ Translate[isLang]?.Service?.title}</h2>
                                <p data-aos="fade-up" className="aos-init aos-animate" dir={isLang==="en"?'ltr':'rtl'}>{ Translate[isLang]?.Service?.dec} </p>
                                
                            </div>
                        </Col>
                    </Row>

                    <div className="container_services">

                        <Container  >
                            <div className="row mt-3 flex justify-content-center  ">
                                {
                                    dataService.map((item, index) => (
                                        <div className="col-lg-6 col-md-6  mt-3 " data-aos="fade-up"  >
                                            <div className="feature-style-five mb-45" style={{ marginBottom: '30px' }}>
                                                <div className="icon mb-30" style={{ marginBottom: '30px' }}>
                                                    <img src={item.img} alt="icon" />
                                                </div>
                                                <h2 className="mb-20" >
                                                    <Link className="sect-title-two text-light" to={`/services/${item.path}`}>{isLang === "en" ? item.title : item.titleAr}</Link>
                                                </h2>
                                                <p  dir={isLang==="en"?'ltr':'rtl'}> {isLang === "en" ? item.description?.split(" ")?.slice(0, 20)?.join(" ") : item.descriptionAr?.split(" ")?.slice(0, 20)?.join(" ")}</p>
                                                <div className="read_more_services">

                                                    <h6 className="mb-20">
                                                        <Link className="sect-title-two_details" to={`/services/${item.path}`}>{ Translate[isLang]?.Service?.readMore}</Link>
                                                    </h6>
                                                </div>
                                            </div>

                                        </div>
                                    ))
                                }
                            </div>
                        </Container>
                    </div>
                </Container>
            </div>

            <section className="call-to-action   " style={{ paddingTop: '140px' }}>
                <img className="call-shape shape-one" src={img.AnimationLine} alt="shape" />
                <img className="call-shape shape-two d-none d-lg-inline-block" src={img.Icons6} alt="icon" />
                <img className="call-shape shape-three d-none d-lg-inline-block" src={img.Icons7} alt="icon" />
                <img className="call-shape shape-four d-none d-lg-inline-block" src={img.Icons8} alt="icon" />
                <img className="call-shape shape-five d-none d-lg-inline-block" src={img.Icons9} alt="icon" />
                <img className="call-shape shape-six d-none d-lg-inline-block" src={img.Icons5} lt="" />
                <img className="call-shape shape-seven d-none d-lg-inline-block" src={img.Icons6} alt="" />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-md-8">
                            <div className="call-to-wrapper">
                                <h3 className="title-big mb-60 aos-init aos-animate" data-aos="fade-up"> { Translate[isLang]?.Service?.ServiceWorker} </h3>
                                {/* <a className="theme_btn aos-init aos-animate" data-aos="fade-up" href="contact.html">Contact Us</a> */}

                                <Link to={'/contact'}  >
                                    <div className="btn__main mt-5" data-aos="fade-up">
                                        <button className="btn theme_btn"  >  { Translate[isLang]?.Service?.btnContactUs} </button>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Services
