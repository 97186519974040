import { Link, NavLink, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import img from '../../assets/Img';
import Icons from '../../constants/Icons';
import routes from './routes';
import { LocalizationContext } from '../../context/LangChange';
import './navbar.scss';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import Translate from './Translate';
const NavBar = ({ navbarColor }) => {
  const location = useLocation();

  let { isLang, setIsLang } = useContext(LocalizationContext);
  const [activeLink, setActiveLink] = useState(0);

  const handleLinkClick = (index) => {
    setActiveLink(index);
    setVisibleRight(false)
  };
  const menuVariants = {
    hidden: {
      opacity: 0,
      x: isLang === "en" ? '100%' : '-100%',
      transition: {
        duration: 0.6,
      },
    },
    visible: {
      opacity: 1,
      x: '0%',
      transition: {
        duration: 0.6,
      },
    },
    exit: {
      opacity: 0,
      x: '100%',
      transition: {
        duration: 0.6,
      },
    },
  };
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleLocalizationChange = async (action) => {
    if (action === 'en') {
      setIsLang('en')
    } else if (action === 'ar') {
      setIsLang('ar')
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [visibleRight, setVisibleRight] = useState(false);
  // const fetchNav = [
  //   { path: '/services/ai', title_en: 'AI and Machine Learning' },
  //   { path: '/services/mobile', title_en: 'Web and Mobile Developments' },
  //   { path: '/services/blockchain', title_en: ' blockchain' },
  //   { path: '/services/management', title_en: 'Mobile App Management ' },
  //   { path: '/services/Customer', title_en: ' Customer-Centric Approach' },
  //   { path: '/services/Innovation', title_en: 'Innovation and Collaboration ' },
  //   { path: '/services/Social', title_en: 'Social Responsibility ' },
  // ]

  return (
    <>
      <AnimatePresence >
        {scrollPosition <= 400 && (
          <motion.div
            dir={`${isLang === "en" ? 'ltr' : 'rtl'}`}
            className="navbar__social  "
            initial={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}>

            <Navbar className={` px-4 navbar__social-Container `} >
              <div className="container">
                <div className="calling">
                  <div className='Logo__RightClick' as={Link} to={'/'}>
                    <img src={img.Logo} width={"150px"} height={'45px'} alt="" />
                  </div>
                </div>
                <div className="social__vigtas">

                  <div className="time">
                    <motion.a rel="noreferrer"
                      whileHover={{ scale: 1.2 }}
                      transition={{ duration: 0.3 }}
                    >
                      <Icons.Clock />
                    </motion.a>
                    <div className="contant">
                      <span>{Translate[isLang]?.navbarTop?.time}</span>
                      <h6 className="text-white">{Translate[isLang]?.navbarTop?.timeDetails} </h6>
                    </div>
                  </div>
                  <div className="time location">
                    <motion.a rel="noreferrer"
                      whileHover={{ scale: 1.2 }}
                      transition={{ duration: 0.3 }}
                    >
                      <Icons.Location color={'#ffff'} />
                    </motion.a>
                    <div className="contant">
                      <span>{Translate[isLang]?.navbarTop?.location}</span>
                      <h6 className="text-white">{Translate[isLang]?.navbarTop?.locationDetails}</h6>
                    </div>
                  </div>
                  <div className="time Phone">
                    <motion.a rel="noreferrer"
                      whileHover={{ scale: 1.2 }}
                      transition={{ duration: 0.3 }}
                    >
                      <Icons.CallBold color={'#ffff'} />
                    </motion.a>
                    <div className="contant">
                      <span>{Translate[isLang]?.navbarTop?.phone}</span>
                      <h6 className="text-white" dir='ltr'>
                        <a href="tel:+201090105214" target="_blank" rel="noreferrer" className='text-light' > +20 109 010 5214 </a>
                      </h6>
                    </div>
                  </div>

                </div>
              </div>
            </Navbar>
          </motion.div>
        )}
      </AnimatePresence >
      <div className="main-nav">
        <Navbar
          dir={`${isLang === "en" ? 'ltr' : 'rtl'}`}
          fixed={scrollPosition > 400 ? 'top' : 'top'}
          collapseOnSelect
          expand="sm"
          className={`px-3  ${navbarColor}  py- ${scrollPosition > 400 ? 'scroll navbar-navOnthorPAge shadow-sm ' : ''}`}
        >
          <Container  >
            <div className='app__menu-left app__menu-left-logo '>
              <div className='Logo__RightClick-sm ' as={Link} to={'/'}>
                <img src={img.Logo} width={"150px"} height={'45px'} alt="" />
              </div>
            </div>
            <div className='app__menu-left app__menu-left-sm'>

              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto me-2">
                  <li className={`nav-item ${location.pathname === '/' ? 'active' : ''}`}>
                    <Link className="nav-link" to="/">{isLang === "en" ? 'Home' : 'الرئيسيه'}</Link>
                  </li>
                  <li className={`nav-item ${location.pathname === '/about' ? 'active' : ''}`}>
                    <Link className="nav-link" to="/about">{isLang === "en" ? 'About' : 'من نحن'}</Link>
                  </li>
                  <li className={`nav-item ${location.pathname === '/services' ? 'active' : ''}`}>
                    <Link className="nav-link" to="/services">{isLang === "en" ? 'Services' : 'خدماتنا  '}</Link>
                  </li>

                  {/*                <li
                    className={`nav-item dropdown ${isDropdownOpen ? 'active' : ''}`}
                    onMouseEnter={handleDropdownOpen}
                    onMouseLeave={handleDropdownClose} >
                    <NavLink
                      className="nav-link dropdown-toggle"
                      to="/services"
                      id="servicesDropdown"
                      role="button"
                      // aria-expanded="false" 
                      aria-expanded={isDropdownOpen ? 'true' : 'false'}

                    >
                      <div className='flex justify-content-center align-items-center' style={{ display: 'flex', gap: '10px' }}>
                        <span>Services</span>
                        <i className="fa fa-chevron-down" style={{ fontSize: '15px' }}></i>
                      </div>
                    </NavLink>
                    <ul className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`} aria-labelledby="servicesDropdown">
                      {fetchNav.map((service, index) => (
                        <li key={index}>
                          <Link className={`dropdown-item ${location.pathname === service.path ? 'active' : ''}`} to={service.path}>{service.title_en}</Link>
                        </li>
                      ))}
                    </ul>
                  </li> */}
                  {/* <li className={`nav-item ${location.pathname === '/portfolio' ? 'active' : ''}`}>
                    <Link className="nav-link" to="/portfolio">Portfolio</Link>
                  </li> */}
                  <li className={`nav-item ${location.pathname === '/contact' ? 'active' : ''}`}>
                    <Link className="nav-link" to="/contact">{isLang === "en" ? 'Contact' : 'اتصل بنا  '}</Link>
                  </li>
                </ul>
                {/* <a className="btn btn-primary" href="#contact">Get Started</a> */}
              </div>
            </div>
            <div className='app__menu-left'>
              <div className="change_lang">

                {
                  isLang === "en" ?
                    <motion.div
                      className='app__menu-talk'
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.8 }}
                      onClick={(eventKey) => handleLocalizationChange('ar')}

                    >
                      عربيه
                      <i className="fa-solid fa-globe"></i>
                    </motion.div> :
                    <motion.div
                      className='app__menu-talk'
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.8 }}
                      onClick={(eventKey) => handleLocalizationChange('en')}
                    >  English<i className="fa-solid fa-globe"></i></motion.div>
                }
              </div>
              <Button size='small' rounded outlined severity="secondary" aria-label="Bookmark" icon="pi pi-bars" onClick={() => setVisibleRight(true)} />

              <Sidebar className='bg_sidebar' dir={isLang == "en" ? 'ltr' : 'rtl'} visible={visibleRight} position={`${isLang === "en" ? 'right' : 'left'}`} onHide={() => setVisibleRight(false)}>

                <div className="conatent_sidebar-lg">
                  <div className="logo">
                    <Navbar.Brand as={Link} to={'/'}>
                      <img src={img.Logo} className=' mb-2' width={"150px"} height={'45px'} alt="" />
                    </Navbar.Brand>
                    <hr />
                    <div className="title">
                      <h4>{Translate[isLang]?.Sidebar?.title}</h4>
                      <p>{Translate[isLang]?.Sidebar?.dec}</p>
                    </div>

                    <hr />
                    <div className="title">
                      <div className="footer__widget ">
                        <div className="info-widget info-widget2">
                          <h4 className="offset-title mb-20">{Translate[isLang]?.Sidebar?.ContactUS}  </h4>
                          <p>
                            <i className="fa fa-address-book"></i>
                            {Translate[isLang]?.navbarTop?.locationDetails}
                          </p>
                          <p>
                            <i className="fa fa-phone"></i>
                            <a href="tel:+201090105214" dir='ltr' target="_blank" rel="noreferrer" className='text-light' > +20 109 010 5214 </a>
                          </p>
                          <p >
                            <i className="fa-brands fa-whatsapp"></i>
                            <a href="https://wa.me/+201090105214" dir='ltr' target="_blank" rel="noreferrer" className='text-light' > +20 109 010 5214 </a>
                          </p>
                          <p>
                            <i className="fa fa-envelope-open"></i>
                            <a href="mailto:info@vigtas.com" target="_blank" rel="noreferrer" className='text-light' >info@vigtas.com</a>
                          </p>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="navbar_sm_page  ">

                  <div className="   d-flex flex-column   " >
                    <section className={'routes  '} >
                      {routes?.map((root, i) => {
                        return (
                          <div key={i}>
                            <Link
                              to={root.path}
                              className={`link  ${activeLink === i ? 'active' : ''}  ${isLang === "en" ? 'justify-content-start' : 'justify-content-start'}`}
                              onClick={() => handleLinkClick(i)}
                            >
                              <div className={`icon  ${activeLink === i ? 'active' : ''}`} id={root.name} data-tooltip-content={root.nameEn}>
                                {root.icon}
                              </div>
                              <div className="link_text">{isLang === "en" ? root.nameEn : root.nameAr}</div>
                            </Link>
                          </div>
                        );
                      })}
                    </section>
                  </div>

                  <div className="change_lang-sidebar " dir='rtl '>

                    {
                      isLang === "en" ?
                        <motion.div
                          className='app__menu-talk'
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.8 }}
                          onClick={(eventKey) => handleLocalizationChange('ar')}

                        >
                          عربيه
                          <i className="fa-solid fa-globe"></i>
                        </motion.div> :
                        <motion.div
                          className='app__menu-talk'
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.8 }}
                          onClick={(eventKey) => handleLocalizationChange('en')}

                        >
                          English
                          <i className="fa-solid fa-globe"></i>

                        </motion.div>
                    }
                  </div>
                </div>

              </Sidebar>
            </div>

          </Container>
        </Navbar>
      </div >
    </>
  )
}

export default NavBar

