import AOS from 'aos'
import React, { useContext, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import img from '../../assets/Img'
import Icons from '../../constants/Icons'
import { LocalizationContext } from '../../context/LangChange'
import About from './About'
import AboutLast from './AboutLast'
import Services from './Services'
import Translate from './Translate'
import './style.scss'

const Home = () => {
  let { isLang } = useContext(LocalizationContext);

  useEffect(() => {
    window.scroll(0, 0)
    AOS.init({
      duration: '1500'
    });
  }, [])

  const translatedTitle = Translate[isLang]?.home?.title || '';

  return (
    <>
      <div className="app__home overflow-hidden gradient-bg ">
        <Icons.HeroShape className="banner-shape shape-seven d-none d-xl-inline-block" />
        <Icons.HeroShape2 className="banner-shape shape-six d-none d-xl-inline-block" />

        <Container >
          <Row dir={isLang == "en" ? 'ltr' : 'rtl'} className='gx-4 gx-xxl-5 d-flex justify-content-center align-item-center'>
            <Col xl={6} lg={6} sm={12} md={12}  >
              <div data-aos={isLang == "en" ? 'fade-left' : 'fade-right'} className={`app__home_contant  animation-linear   fadeinleft animation-duration-1000 `}>
                <div className="header">
                  <h4 className="hero-sub-title mb-25">{translatedTitle}</h4>
                </div>
                <div className="title">
                  <h1 className="main-title-two" >
                    { }
                    {isLang === "en" ? 'Navigating the Digital Frontier  ,' : 'الإبحار في الحدود الرقمية ،'}
                  </h1>

                  <h1 className="main-title-two" >
                    {isLang === "en" ? <> One <span>Innovation</span> at a Time.  </> :
                      <> <span>ابتكار </span>واحد في كل مرة. </>
                    }

                  </h1>
                </div>
                {/*           <div className="btn__main">
                  <button className="btn theme_btn"  >  {Translate["en"].home.btn} </button>
                </div> */}
              </div>
            </Col>
            <Col xl={6} lg={6} sm={12} md={12} className='app__home_image'>
              <div data-aos={isLang == "en" ? 'fade-left' : 'fade-right'} className={`  h-100 w-100      animation-linear  animation-duration-1000 `} >
                {
                  isLang ==="en"?

                  <img src={img.ServicehomePAge} className='w-100' alt="ilus"   />:
                  <img src={img.ServicehomePAge} className='w-100' alt="ilus" style={{transform: 'rotateY(180deg)'}} />
                  
                }
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <About />
      <Services />

      <AboutLast />



    </>
  )
}

export default Home
