import Logo from "./Images/logo.png";

import Icons1 from "./Images/About/icon-28c.png";
import Icons2 from "./Images/About/icon-29c.png";
import Icons3 from "./Images/About/icon-30c.png";
import Icons4 from "./Images/About/icon-31c.png";
import Icons5 from "./Images/About/icon-32c.png";

import Icons6 from "./Images/icons/icon-39c.png";
import Icons7 from "./Images/icons/icon-41c.png";
import Icons8 from "./Images/icons/icon-42c.png";
import Icons9 from "./Images/icons/icon-43c.png";

import Icons10 from "./Images/Service/icon-33c.png";
import Icons11 from "./Images/Service/icon-34c.png";
import Icons12 from "./Images/Service/icon-35c.png";
import Icons13 from "./Images/Service/icon-36c.png";
import Icons14 from "./Images/Service/icon-37c.png";
import Icons15 from "./Images/Service/icon-38c.png";

import star1 from "./Images/icons/star-3b.png";
import star4 from "./Images/icons/star-4b.png";
import star2 from "./Images/icons/star-5b.png";
import star3 from "./Images/icons/star-6b.png";

import headerLeft from "./Images/Service/img-01.png";
import headerRight from "./Images/Service/img-02.png";

import Service1 from "./Images/Images/AI 2.jpg";
import CustomerCentricApproachService1 from "./Images/Images/Customer-Centric Approach.jpg";
import InnovationService1 from "./Images/Images/Innovation and Collaboration 2.jpg";
import ServiceMobilapp from "./Images/Images/Mobile app.jpg";
import socialService1 from "./Images/Images/Sccial_Responsibility-transformed.png";
import BlockchainService1 from "./Images/Images/blockchain.jpg";
import Servicehome from "./Images/Images/home.png";
import ServicehomePAge from "./Images/Images/homePage.png";
import AboutMession from "./Images/Images/mession.png";
import AboutVision from "./Images/Images/vision.png";
import webService1 from "./Images/Images/web and app.jpeg";
import Animation from "./Images/Service/shape.png";

import AnimationLine from "./Images/Service/line-round-6b.png";



import ImagesAi from './Images/Images/AI.jpg';

import SkillsDart from './Images/skills/dart.png';
import SkillsHtml from './Images/skills/html-5.png';
import Skillsjavaw from './Images/skills/java (2).png';
import SkillsJs from './Images/skills/js.png';
import Skillskotlin from './Images/skills/kotlin-logo.png';
import SkillsPHP from './Images/skills/php.png';
import SkillsPython from './Images/skills/python.png';
import SkillsSwift from './Images/skills/swift.png';



const img = {
    Skillskotlin, 
    AboutMession,
    AboutVision,
    SkillsPython,
    ServicehomePAge,
    ServiceMobilapp,
 
    Skillsjavaw,  
    SkillsDart, 
    SkillsSwift, 
    SkillsDart,
    SkillsHtml,
    SkillsJs,
    SkillsPHP,
    Servicehome, 
    ImagesAi,
    CustomerCentricApproachService1,
    BlockchainService1,
    InnovationService1,
    socialService1,
    star1,
    Service1,
    webService1,
    star2,
    star3,
    star4,
    Logo,  
    Icons1,
    Icons2,
    Icons3,
    Icons4,
    Icons5,
    Animation,
    AnimationLine,
    Icons6,
    Icons7,
    Icons8,
    Icons9,
    headerLeft,  
    headerRight,
    Icons10,
    Icons11,
    Icons12,
    Icons13,
    Icons14,
    Icons15
}

export default img;
