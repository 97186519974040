import React, { useContext } from 'react'
import { Col, Row } from 'react-bootstrap'
import { LocalizationContext } from '../../context/LangChange';
import img from '../../assets/Img';

const Technology = () => {
    let { isLang } = useContext(LocalizationContext);

    return (
        <div>
            <h3 className="sect-title-two fw-bold mb-20" style={{ marginBottom: '15px', }}>{isLang === "en" ? "Our technology" : '  تقنيتنا   '}</h3>
            <div className="tools-list mt-20 mb-40" style={{ marginBottom: '40px', marginTop: '30px' }}>
                <Row>
                    <Col xl={3} lg={3} md={4} sm={6} >
                        <div style={{ maxHeight: '148px', maxWidth: '100%' }}>
                            <span><img src={img.Skillsjavaw} className='w-100 h-100' style={{ objectFit: 'contain' }} alt="html" /></span>
                        </div>
                    </Col>
                    <Col xl={3} lg={3} md={4} sm={6} >
                        <div style={{ maxHeight: '148px', maxWidth: '100%' }}>
                            <span><img src={img.Skillskotlin} className='w-100 h-100' style={{ objectFit: 'contain' }} alt="html" /></span>
                        </div>
                    </Col>
                    <Col xl={3} lg={3} md={4} sm={6} >
                        <div style={{ maxHeight: '148px', maxWidth: '100%' }}>
                            <span><img src={img.SkillsSwift} className='w-100 h-100' style={{ objectFit: 'contain' }} alt="html" /></span>
                        </div>
                    </Col>
                    <Col xl={3} lg={3} md={4} sm={6} >
                        <div style={{ maxHeight: '148px', maxWidth: '100%' }}>
                            <span><img src={img.SkillsDart} className='w-100 h-100' style={{ objectFit: 'contain' }} alt="html" /></span>
                        </div>
                    </Col>

                    <Col xl={3} lg={3} md={4} sm={6} >
                        <div style={{ maxHeight: '148px', maxWidth: '100%' }}>
                            <span><img src={img.SkillsPHP} className='w-100 h-100' style={{ objectFit: 'contain' }} alt="html" /></span>
                        </div>
                    </Col>

                    <Col xl={3} lg={3} md={4} sm={6} >
                    <div style={{ maxHeight: '148px', maxWidth: '100%' }}>
                          <span><img src={img.SkillsJs} className='w-100 h-100' style={{ objectFit: 'contain' }} alt="html" /></span>
                        </div>
                    </Col>

                    <Col xl={3} lg={3} md={4} sm={6} >
                    <div style={{ maxHeight: '148px', maxWidth: '100%' }}>
                          <span><img src={img.SkillsHtml} className='w-100 h-100' style={{ objectFit: 'contain' }} alt="html" /></span>
                        </div>
                    </Col>

                    <Col xl={3} lg={3} md={4} sm={6} >
                    <div style={{ maxHeight: '148px', maxWidth: '100%' }}>
                          <span><img src={img.SkillsPython} className='w-100 h-100' style={{ objectFit: 'contain' }} alt="html" /></span>
                        </div>
                    </Col>
                </Row> 
            </div>
        </div>
    )
}

export default Technology
