import React from 'react'
import { Image } from 'primereact/image';

const HeaderDeails = ({ title, description, img }) => {
    return (
        <div className="service-details-content-top mb-60" style={{ paddingTop: '60px', paddingBottom: '60px' }}>

            <h1 className="sect-title">{title}</h1>
            <p className="text-white">
                {description}
            </p>
            <div className="card flex justify-content-center overflow-hidden">
                <Image src={img} alt="Image" width="250" preview />
            </div>
        </div>
    )
}

export default HeaderDeails
