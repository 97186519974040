import React, { useEffect, useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import img from '../../assets/Img';
import HeaderBase2 from '../../components/HeaderBase2';
import Icons from '../../constants/Icons';
import { LocalizationContext } from '../../context/LangChange';
import HeaderDeails from './HeaderDeails';
import AOS from 'aos';
import './details.scss';
import Translate from './Translate';
import Technology from './Technology';

const Details = () => {
  let { type } = useParams();
  const location = useLocation();
  let { isLang } = useContext(LocalizationContext);

  useEffect(() => {
    AOS.init({
      duration: '1500'
    });
  }, []);
  const fetchNav = [
    { path: '/services/ai', title_en: 'AI and Machine Learning' },
    { path: '/services/mobile', title_en: 'Web and Mobile Developments' },
    { path: '/services/blockchain', title_en: ' Blockchain' },
    { path: '/services/management', title_en: 'Mobile App Management ' },
    { path: '/services/Customer', title_en: ' Customer-Centric Approach' },
    { path: '/services/Innovation', title_en: 'Innovation and Collaboration ' },
    { path: '/services/Social', title_en: 'Social Responsibility ' },
  ]

  const dataService = [
    { path: 'ai', icons: <Icons.Service5 />, img: img.Service1, title: 'AI and Machine Learning', description: 'We harness the power of Artificial Intelligence and Machine Learning to develop intelligent, data-driven solutions that transform businesses. Our expertise in natural language processing, computer vision, predictive analytics, and recommendation systems allows us to deliver solutions that optimize processes, enhance decision-making, and provide personalized experiences to users.' },
    { path: 'mobile', icons: <Icons.Service5 />, img: img.webService1, title: 'Web and Mobile Developments', description: `At Vigtas, we take pride in creating cutting-edge web and mobile applications that blend seamless functionality with stunning design. Our team of skilled developers, designers, and user experience experts work collaboratively to deliver intuitive, responsive, and user-friendly applications. Whether it's a website, e-commerce platform, or mobile app, we ensure that our solutions exceed our clients' expectations and enhance their online presence.` },
    { path: 'blockchain', icons: <Icons.Service5 />, img: img.BlockchainService1, title: 'Blockchain', description: 'As pioneers in blockchain technology, we build secure, transparent, and decentralized applications that revolutionize industries. Our blockchain solutions encompass smart contracts, supply chain management systems, and token-based platforms, providing enhanced security and trust in business processes.' },
    { path: 'management', icons: <Icons.Service5 />, img: img.ServiceMobilapp, title: 'Mobile App Management ', description: `Our mobile app management services ensure that our clients' applications operate at peak performance and remain up-to-date with the latest technologies. From app updates and performance optimization to security enhancements, we manage the complete lifecycle of mobile applications, allowing our clients to focus on their core business objectives.` },
    { path: 'Customer', icons: <Icons.Service5 />, img: img.CustomerCentricApproachService1, title: 'Customer-Centric Approach', description: `At Vigtas, our clients' success is at the heart of everything we do. We prioritize understanding their unique needs, challenges, and goals, and tailor our solutions to meet those specific requirements. With open communication and transparency, we foster strong partnerships and deliver outcomes that exceed expectations.` },
    { path: 'Innovation', icons: <Icons.Service5 />, img: img.InnovationService1, title: 'Innovation and Collaboration', description: 'Innovation is in our DNA. We cultivate a culture of continuous learning and exploration, encouraging our team members to push boundaries and think outside the box. By collaborating with our clients and stakeholders, we create solutions that address real-world problems and create lasting value.' },
    { path: 'Social', icons: <Icons.Service5 />, img: img.socialService1, title: 'Social Responsibility', description: 'As a socially responsible company, we are committed to using technology for positive change. We actively support initiatives that promote sustainability, diversity, and inclusivity, contributing to a better and more equitable world.' }
  ]

  useEffect(() => {
    window.scroll(0, 0)
  }, [type])

  return (
    <div>
      {type === "ai" && <HeaderBase2 title={Translate[isLang]?.AI.title} isLang={isLang} />}
      {type === "mobile" && <HeaderBase2 title={Translate[isLang]?.WebApp.title} isLang={isLang} />}
      {type === "blockchain" && <HeaderBase2 title={Translate[isLang]?.Blockchain.title} isLang={isLang} />}
      {type === "management" && <HeaderBase2 title={Translate[isLang]?.Mobile.title} isLang={isLang} />}
      {type === "Customer" && <HeaderBase2 title='Customer-Centric Approach' />}
      {type === "Innovation" && <HeaderBase2 title='Innovation and Collaboration' />}
      {type === "Social" && <HeaderBase2 title='Social Responsibility' />}

      <Container>
        <div className="app__service_details" dir={isLang === "en" ? 'ltr' : 'rtl'}>
          {
            type === "ai" &&
            <HeaderDeails
              title={Translate[isLang]?.AI.title}
              description={Translate[isLang]?.AI.dec}
              img={dataService[0].img}
            />
          }
          {
            type === "mobile" &&
            <HeaderDeails
              title={Translate[isLang]?.WebApp.title}
              description={Translate[isLang]?.WebApp.dec}
              img={dataService[1].img}
            />
          }
          {
            type === "blockchain" &&
            <HeaderDeails
              title={Translate[isLang]?.Blockchain.title}
              description={Translate[isLang]?.Blockchain.dec}
              img={dataService[2].img}
            />
          }
          {
            type === "management" &&
            <HeaderDeails
              title={Translate[isLang]?.Mobile.title}
              description={Translate[isLang]?.Mobile.dec}
              img={dataService[3].img}
            />
          }

          {
            type === "mobile" &&
            <div className=" row gx-4 gx-xxl-5">
              <div className="col-lg-8">
                <div className="widget-left-section">
                  <h3 className="sect-title-two fw-bold mb-20" style={{ marginBottom: '20px' }}> {isLang === "en" ? 'Web Development Specialization:' : 'تخصص تطوير الويب:'}  </h3>
                  <h6 className="text-white">   {isLang === "en" ? 'Our web development team harnesses the power of modern technologies and frameworks to create dynamic, responsive, and feature-rich websites. We specialize in a wide range of web development services, including:' : 'يستغل فريق تطوير الويب لدينا قوة التقنيات والأطر الحديثة لإنشاء مواقع ويب ديناميكية وسريعة الاستجابة وغنية بالميزات. نحن متخصصون في مجموعة واسعة من خدمات تطوير الويب ، بما في ذلك:'} </h6>
                  <p className="text-white mt-15 mb-45" style={{ marginBottom: '45px', marginTop: '15px' }}>
                    <ul className={`${isLang === "en" ? 'text-list text-listEn' : 'text-list text-listAr'} list-none  `} >
                      <li dir={isLang === "en" ? 'ltr' : 'rtl'}>
                        {
                          isLang === "en" ?
                            <>
                              <strong className='skills_title'> Frontend Development : </strong> Our frontend experts craft captivating user interfaces using HTML5, CSS3, and JavaScript frameworks like React, Angular, and Vue.js. We focus on delivering seamless user experiences across devices and browsers.
                            </> :
                            <>
                              <strong className='skills_title'>    تطوير التطبيقات الأصلية: </strong> نقوم بإنشاء تطبيقات جوال أصلية لنظام iOS (باستخدام Swift) و Android (باستخدام Kotlin و Java). يضمن هذا النهج الأداء الأمثل وتجربة مستخدم سلسة تتكامل تمامًا مع الأنظمة الأساسية المعنية.

                            </>
                        }
                        <i className="pi pi-check"></i>
                      </li>
                      <li dir={isLang === "en" ? 'ltr' : 'rtl'}>
                        {
                          isLang === "en" ?
                            <>
                              <i className="pi pi-check"></i>
                              <strong className='skills_title'> Backend Development : </strong>  Our backend engineers build robust, scalable, and secure server-side architectures using languages such as Node.js, Python, Java, and PHP. We integrate databases, APIs, and services to ensure your web applications perform seamlessly.
                            </> :
                            <>
                              <i className="pi pi-check"></i>
                              <strong className='skills_title'>   . تطوير الواجهة الخلفية : </strong>  يقوم مهندسو الواجهة الخلفية لدينا ببناء بنى قوية وقابلة للتطوير وآمنة من جانب الخادم باستخدام لغات مثل Node.js و Python و Java و PHP. نقوم بدمج قواعد البيانات وواجهات برمجة التطبيقات والخدمات لضمان أداء تطبيقات الويب الخاصة بك بسلاسة.
                            </>
                        }
                      </li>
                      <li dir={isLang === "en" ? 'ltr' : 'rtl'}> <i className="pi pi-check"></i>
                        {
                          isLang === "en" ?
                            <>
                              <strong className='skills_title'> Full-Stack Development : </strong>   Our full-stack developers bring together frontend and backend technologies to create end-to-end solutions that cater to every aspect of your project, from user interactions to server logic.
                            </> :
                            <>
                              <strong className='skills_title'>  تطوير المجموعة الكاملة : </strong>  يجمع مطورو المجموعة الكاملة لدينا تقنيات الواجهة الأمامية والخلفية لإنشاء حلول شاملة تلبي كل جانب من جوانب مشروعك ، من تفاعلات المستخدم إلى منطق الخادم.
                            </>
                        }
                      </li>
                      <li dir={isLang === "en" ? 'ltr' : 'rtl'}> <i className="pi pi-check"></i>
                        {
                          isLang === "en" ?
                            <>
                              <strong className='skills_title'> E-Commerce Solutions : </strong>  We specialize in developing comprehensive e-commerce platforms that provide exceptional shopping experiences. Our solutions include secure payment gateways, inventory management, personalized recommendations, and user-friendly interfaces.
                            </> :
                            <>
                              <strong className='skills_title'>حلول التجارة الإلكترونية : </strong> نحن متخصصون في تطوير منصات تجارة إلكترونية شاملة توفر تجارب تسوق استثنائية. تشمل حلولنا بوابات دفع آمنة وإدارة المخزون والتوصيات الشخصية وواجهات سهلة الاستخدام.
                            </>
                        }
                      </li>
                      <li dir={isLang === "en" ? 'ltr' : 'rtl'}> <i className="pi pi-check"></i>
                        {
                          isLang === "en" ?
                            <>
                              <strong className='skills_title'> Custom Web Applications : </strong>  We thrive on challenging projects that require custom solutions. Whether it's a complex enterprise application, a social networking platform, or a content management system, our team is equipped to handle it all.
                            </> :
                            <>
                              <strong className='skills_title'> تطبيقات الويب المخصصة   : </strong>   نحن نزدهر في المشاريع الصعبة التي تتطلب حلولاً مخصصة. سواء كان تطبيقًا معقدًا للمؤسسات ، أو نظامًا أساسيًا للشبكات الاجتماعية ، أو نظامًا لإدارة المحتوى ، فإن فريقنا مجهز للتعامل مع كل ذلك.
                            </>
                        }
                      </li>
                    </ul>
                  </p>
                  <hr />
                  <h3 className="sect-title-two fw-bold mb-20" style={{ marginBottom: '20px' }}>
                    {isLang === "en" ? 'Mobile  Development Specialization: ' : 'تخصص تطوير الهاتف المحمول:'}
                  </h3>
                  <h6 className="text-white"> {isLang === "en" ? 'In the mobile realm, Vigtas stands as a trailblazer, consistently pushing the boundaries of mobile app development. Our expertise encompasses:' : 'في عالم الأجهزة المحمولة ، يقف Vigtas كرائد ، حيث يدفع باستمرار حدود تطوير تطبيقات الأجهزة المحمولة. تشمل خبرتنا:'} </h6>
                  <p className="text-white mt-15 mb-45" style={{ marginBottom: '45px', marginTop: '15px' }}>
                    <ul className={`${isLang === "en" ? 'text-list text-listEn' : 'text-list text-listAr'} list-none  `}>
                      <li> <i className="pi pi-check"></i>
                        {
                          isLang === "en" ?
                            <>
                              <strong className='skills_title'> Native App Development : </strong> We create native mobile apps for iOS (using Swift) and Android (using Kotlin and Java). This approach ensures optimal performance and a seamless user experience that integrates perfectly with the respective platforms.
                            </> :
                            <>
                              <strong className='skills_title'>. تطوير التطبيقات الأصلية : </strong> نقوم بإنشاء تطبيقات جوال أصلية لنظام iOS (باستخدام Swift) و Android (باستخدام Kotlin و Java). يضمن هذا النهج الأداء الأمثل وتجربة مستخدم سلسة تتكامل تمامًا مع الأنظمة الأساسية المعنية.
                            </>
                        }
                      </li>

                      <li> <i className="pi pi-check"></i>
                        {
                          isLang === "en" ?
                            <>
                              <strong className='skills_title'> Cross-Platform Development : </strong>
                              Our developers are proficient in using tools like Flutter to build cross-platform apps that maintain a native feel while sharing a significant portion of the codebase. This approach accelerates development and reduces costs.
                            </> :
                            <>
                              <strong className='skills_title'> التطوير عبر الأنظمة الأساسية : </strong>
                              يتقن مطورونا استخدام أدوات مثل Flutter لبناء تطبيقات عبر الأنظمة الأساسية تحافظ على الشعور الأصلي أثناء مشاركة جزء كبير من قاعدة التعليمات البرمجية. هذا النهج يسرع التنمية ويقلل من التكاليف.
                            </>
                        }
                      </li>
                      <li> <i className="pi pi-check"></i>
                        {
                          isLang === "en" ?
                            <>
                              <strong className='skills_title'> Mobile App UI/UX Design : </strong>
                              Our design team crafts visually appealing and intuitive user interfaces that enhance user engagement. We focus on creating a consistent and delightful experience across different devices.
                            </> :
                            <>
                              <strong className='skills_title'>   تصميم واجهة مستخدم / UX لتطبيقات الهاتف المحمول:  </strong>
                              يصمم فريق التصميم لدينا واجهات مستخدم جذابة بصريًا وبديهية تعزز تفاعل المستخدم. نحن نركز على إنشاء تجربة متسقة وممتعة عبر الأجهزة المختلفة.
                            </>
                        }
                      </li>
                      <li> <i className="pi pi-check"></i>
                        {
                          isLang === "en" ?
                            <>
                              <strong className='skills_title'> App Testing and Quality Assurance : </strong>
                              We adhere to rigorous testing methodologies to identify and rectify bugs, glitches, and performance issues. Our QA processes ensure your app functions flawlessly across various devices and scenarios.
                            </> :
                            <>
                              <strong className='skills_title'>   اختبار التطبيق وضمان الجودة: </strong>
                              نحن نلتزم بمنهجيات اختبار صارمة لتحديد الأخطاء ومواطن الخلل ومشكلات الأداء وتصحيحها. تضمن عمليات ضمان الجودة لدينا أن يعمل تطبيقك بشكل لا تشوبه شائبة عبر مختلف الأجهزة والسيناريوهات.                            </>
                        }
                      </li>

                    </ul>
                  </p>
                  <hr />
                  <h3 className="sect-title-two fw-bold mb-20" style={{ marginBottom: '15px', marginTop: '45px' }}>{isLang === "en" ? "Our Skills" : "المهارات التي تميزنا: "} </h3>
                  <p className="text-white">
                    {
                      isLang === "en" ?
                        `  At Vigtas, we are more than just developers – we are digital architects, crafting the foundations for success in the digital age. Whether it's web or mobile, we take your concepts and transform them into functional, user-friendly, and elegant solutions that leave a lasting impact. Join us in shaping the future of technology. ` :
                        `في Vigtas ، نحن أكثر من مجرد مطورين - نحن مهندسون معماريون رقميون ، نصنع أسس النجاح في العصر الرقمي. سواء كان ذلك على شبكة الإنترنت أو الهاتف المحمول ، فإننا نأخذ مفاهيمك ونحولها إلى حلول عملية وسهلة الاستخدام وأنيقة تترك أثرًا دائمًا. انضم إلينا في تشكيل مستقبل التكنولوجيا.`
                    }
                  </p>
                  <p className="text-white mt-15 mb-45" style={{ marginBottom: '5px', marginTop: '15px' }}>
                    <ul className={`${isLang === "en" ? 'text-list text-listEn' : 'text-list text-listAr'} list-none  `}>
                      <li> <i className="pi pi-check"></i>
                        {
                          isLang === "en" ?
                            <>
                              <strong className='skills_title'> Technical Expertise : </strong>
                              Our developers are well-versed in a diverse array of programming languages, frameworks, and tools, enabling us to choose the best technology stack for each project's unique requirements.
                            </> :
                            <>
                              <strong className='skills_title'> الخبرة التقنية   : </strong>
                              المطورين لدينا على دراية جيدة بمجموعة متنوعة من لغات البرمجة وأطر العمل والأدوات ، مما يمكننا من اختيار أفضل مجموعة تكنولوجية للمتطلبات الفريدة لكل مشروع.
                            </>
                        }
                      </li>

                      <li> <i className="pi pi-check"></i>
                        {
                          isLang === "en" ?
                            <>
                              <strong className='skills_title'>   Innovation : </strong>We stay updated with the latest industry trends and emerging technologies to offer innovative solutions that keep our clients ahead of the curve.
                            </> :
                            <>
                              <strong className='skills_title'>   الابتكار:    </strong> نبقى على اطلاع بأحدث اتجاهات الصناعة والتقنيات الناشئة لتقديم حلول مبتكرة تجعل عملائنا في الصدارة.
                            </>
                        }
                      </li>

                      <li> <i className="pi pi-check"></i>
                        {
                          isLang === "en" ?
                            <>
                              <strong className='skills_title'>   Collaboration:  </strong>Our teams work closely with clients, fostering transparent communication and collaboration to ensure that every project's vision is brought to life.
                            </> :
                            <>
                              <strong className='skills_title'>    التعاون: </strong>  تعمل فرقنا بشكل وثيق مع العملاء ، مما يعزز التواصل والتعاون الشفافين لضمان تحقيق رؤية كل مشروع.
                            </>
                        }
                      </li>

                      <li> <i className="pi pi-check"></i>
                        {
                          isLang === "en" ?
                            <>
                              <strong className='skills_title'>   Problem Solving  </strong>Complex challenges are opportunities for us to showcase our problem-solving skills. We break down intricate problems and devise effective solutions.
                            </> :
                            <>
                              <strong className='skills_title'>    حل المشكلات:  </strong> التحديات المعقدة هي فرص لنا لعرض مهاراتنا في حل المشكلات. نقوم بتفكيك المشكلات المعقدة وابتكار حلول فعالة.
                            </>
                        }
                      </li>

                      <li> <i className="pi pi-check"></i>
                        {
                          isLang === "en" ?
                            <>
                              <strong className='skills_title'>    Client-Centric Approach  </strong>Our success hinges on client satisfaction. We prioritize understanding your needs, goals, and expectations to tailor solutions that align perfectly with your business objectives.
                            </> :
                            <>
                              <strong className='skills_title'>   نهج يركز على العميل:   </strong> نجاحنا يتوقف على رضا العميل. نعطي الأولوية لفهم احتياجاتك وأهدافك وتوقعاتك لتصميم حلول تتوافق تمامًا مع أهداف عملك.
                            </>
                        }
                      </li>

                    </ul>
                  </p>
                  <hr />
                  <Technology/>

                </div>



              </div>
              <div className="col-lg-4 order-sm-first order-md-first">
                <div className="widget-right-section mb-40">
                  <ul className="list-none service-widget">

                    <li className={`nav-item ${location.pathname === '/services/mobile' ? 'active bg-blue-900' : ''}`}>
                      <Link className="nav-link" to="/services/mobile">{Translate[isLang]?.Service1}</Link>
                    </li>

                    <li className={`nav-item ${location.pathname === '/services/management' ? 'active bg-blue-900' : ''}`}>
                      <Link className="nav-link" to="/services/management"> {Translate[isLang]?.Service2}  </Link>
                    </li>

                    <li className={`nav-item ${location.pathname === '/services/ai' ? 'active bg-blue-900' : ''}`}>
                      <Link className="nav-link" to="/services/ai">{Translate[isLang]?.Service3}</Link>
                    </li>

                    <li className={`nav-item ${location.pathname === '/services/blockchain' ? 'active bg-blue-900' : ''}`}>
                      <Link className="nav-link" to="/services/blockchain">{Translate[isLang]?.Service4}</Link>
                    </li>

                  </ul>
                  <div className="widget-form mt-60" style={{ marginTop: '60px' }}>
                    <h3 className="widget-title mb-20" style={{ marginBottom: '20px' }}>  {Translate[isLang]?.ContactUSTitle}</h3>
                    <p className="mb-40">{Translate[isLang]?.ContactUS}</p>
                    <Link to={'/contact'}>
                      <button className="widget-btn mt-20" style={{ marginTop: '20px' }}>{Translate[isLang]?.ContactUSBtn}  </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          }

          {
            type === "management" &&
            <div className=" row gx-4 gx-xxl-5">
              <div className="col-lg-8">
                <div className="widget-left-section">
                  {
                    isLang === "en" ?
                      <>
                        <h5>  <strong className="skills_title"> Vigtas :</strong>  Transforming Industries with AI and Machine Learning Expertise <br /></h5>
                        <p className="text-white">
                          At Vigtas, we are a leading force in the realm of AI and Machine Learning, harnessing the power of cutting-edge technologies to drive innovation and transformation across industries. Our dedicated team of experts is committed to delivering solutions that leverage the potential of AI and Machine Learning, enabling businesses to make smarter, data-driven decisions and unlock new opportunities.
                        </p>
                      </> :
                      <>
                        <h5>  <strong className="skills_title"> Vigtas :</strong>  إعادة تعريف إدارة تطبيقات الأجهزة المحمولة بالخبرة والابتكار<br /></h5>
                        <p className="text-white mt-3">
                          في العالم الديناميكي لإدارة تطبيقات الأجهزة المحمولة ، تقف Vigtas كشركة برمجيات ذات رؤية مكرسة لتقديم حلول لا مثيل لها. من خلال فهم عميق لتعقيدات دورات حياة تطبيقات الأجهزة المحمولة والالتزام بالابتكار ، نحن متخصصون في تقديم خدمات إدارة تطبيقات الأجهزة المحمولة المتطورة التي تمكّن الشركات من الازدهار في العصر الرقمي.                        </p>
                      </>
                  }
                  <hr />
                  <h3 className="sect-title-two fw-bold mb-20" style={{ marginBottom: '20px' }}>{Translate[isLang]?.Mobile?.Specialization}  </h3>
                  <h6 className="text-white">{Translate[isLang]?.Mobile?.SpecializationTitle}   </h6>
                  <p className="text-white mt-15 mb-45" style={{ marginBottom: '45px', marginTop: '15px' }}>
                    <ul className={`${isLang === "en" ? 'text-list text-listEn' : 'text-list text-listAr'} list-none  `}>
                      {
                        Translate[isLang]?.Mobile?.SpecializationList?.map((item, index) => (
                          <li key={index}> <i className="pi pi-check"></i>
                            <strong className='skills_title'> {item.title}  </strong> {item.dec}
                          </li>
                        ))
                      }
                    </ul>
                  </p>
                  <hr />
                  <h3 className="sect-title-two fw-bold mb-20" style={{ marginBottom: '15px', marginTop: '45px' }}>{Translate[isLang]?.Mobile?.SkillsTitle}  </h3>
                  <p className="text-white"> {Translate[isLang]?.Mobile?.SkillsDec}            </p>
                  <p className="text-white mt-15 mb-45" style={{ marginBottom: '5px', marginTop: '15px' }}>
                    <ul className={`${isLang === "en" ? 'text-list text-listEn' : 'text-list text-listAr'} list-none  `}>
                      {
                        Translate[isLang]?.Mobile?.SkillsList?.map((item, index) => (

                          <li key={index}> <i className="pi pi-check"></i>
                            <strong className='skills_title'> {item.title}  </strong> {item.dec}
                          </li>
                        ))
                      }


                    </ul>
                  </p>
                  <hr />
                  <Technology />

                </div>
              </div>
              <div className="col-lg-4 order-sm-first order-md-first">
                <div className="widget-right-section mb-40">
                  <ul className="list-none service-widget">
                    <li className={`nav-item ${location.pathname === '/services/mobile' ? 'active bg-blue-900' : ''}`}>
                      <Link className="nav-link" to="/services/mobile">{Translate[isLang]?.Service1}</Link>
                    </li>

                    <li className={`nav-item ${location.pathname === '/services/management' ? 'active bg-blue-900' : ''}`}>
                      <Link className="nav-link" to="/services/management"> {Translate[isLang]?.Service2}  </Link>
                    </li>

                    <li className={`nav-item ${location.pathname === '/services/ai' ? 'active bg-blue-900' : ''}`}>
                      <Link className="nav-link" to="/services/ai">{Translate[isLang]?.Service3}</Link>
                    </li>

                    <li className={`nav-item ${location.pathname === '/services/blockchain' ? 'active bg-blue-900' : ''}`}>
                      <Link className="nav-link" to="/services/blockchain">{Translate[isLang]?.Service4}</Link>
                    </li>                  </ul>
                  <div className="widget-form mt-60" style={{ marginTop: '60px' }}>
                    <h3 className="widget-title mb-20" style={{ marginBottom: '20px' }}>  {Translate[isLang]?.ContactUSTitle}</h3>
                    <p className="mb-40">{Translate[isLang]?.ContactUS}</p>
                    <Link to={'/contact'}>
                      <button className="widget-btn mt-20" style={{ marginTop: '20px' }}>{Translate[isLang]?.ContactUSBtn}  </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          }

          {
            type === "ai" &&
            <div className=" row gx-4 gx-xxl-5">
              <div className="col-lg-8">
                <div className="widget-left-section">
                  {
                    isLang === "en" ?
                      <>
                        <h5> <strong className="skills_title"> Vigtas :</strong>  Transforming Industries with AI and Machine Learning Expertise <br /></h5>
                        <p className="text-white">
                          At Vigtas, we are a leading force in the realm of AI and Machine Learning, harnessing the power of cutting-edge technologies to drive innovation and transformation across industries. Our dedicated team of experts is committed to delivering solutions that leverage the potential of AI and Machine Learning, enabling businesses to make smarter, data-driven decisions and unlock new opportunities.
                        </p>
                      </> :
                      <>
                        <h5>  <strong className="skills_title"> Vigtas :</strong>  تحويل الصناعات بخبرات الذكاء الاصطناعي والتعلم الآلي<br /></h5>
                        <p className="text-white mt-3">
                          في Vigtas ، نحن قوة رائدة في مجال الذكاء الاصطناعي والتعلم الآلي ، حيث نسخر قوة التقنيات المتطورة لدفع الابتكار والتحول عبر الصناعات. يلتزم فريق الخبراء المتخصص لدينا بتقديم حلول تستفيد من إمكانات الذكاء الاصطناعي والتعلم الآلي ، وتمكين الشركات من اتخاذ قرارات أكثر ذكاءً وقائمة على البيانات وإطلاق العنان لفرص جديدة.
                        </p>
                      </>
                  }
                  <hr />


                  <h3 className="sect-title-two fw-bold mb-20" style={{ marginBottom: '15px', marginTop: '45px' }}>{Translate[isLang]?.AI?.SkillsTitle}  </h3>
                  <p className="text-white"> {Translate[isLang]?.AI?.SkillsDec}            </p>
                  <p className="text-white mt-5 mt-15 mb-45" style={{ marginBottom: '45px', marginTop: '15px' }}>
                    <ul className={`${isLang === "en" ? 'text-list text-listEn' : 'text-list text-listAr'} list-none  `}>
                      {
                        Translate[isLang]?.AI?.SkillsList?.map((item, index) => (

                          <li> <i className="pi pi-check"></i>
                            <strong className='skills_title'> {item.title}  </strong> {item.dec}
                          </li>
                        ))
                      }


                    </ul>
                  </p>
                  <hr />
                  <Technology />

                </div>
              </div>
              <div className="col-lg-4 order-sm-first order-md-first">
                <div className="widget-right-section mb-40">
                  <ul className="list-none service-widget">
                    <li className={`nav-item ${location.pathname === '/services/mobile' ? 'active bg-blue-900' : ''}`}>
                      <Link className="nav-link" to="/services/mobile">{Translate[isLang]?.Service1}</Link>
                    </li>

                    <li className={`nav-item ${location.pathname === '/services/management' ? 'active bg-blue-900' : ''}`}>
                      <Link className="nav-link" to="/services/management"> {Translate[isLang]?.Service2}  </Link>
                    </li>

                    <li className={`nav-item ${location.pathname === '/services/ai' ? 'active bg-blue-900' : ''}`}>
                      <Link className="nav-link" to="/services/ai">{Translate[isLang]?.Service3}</Link>
                    </li>

                    <li className={`nav-item ${location.pathname === '/services/blockchain' ? 'active bg-blue-900' : ''}`}>
                      <Link className="nav-link" to="/services/blockchain">{Translate[isLang]?.Service4}</Link>
                    </li>                  </ul>
                  <div className="widget-form mt-60" style={{ marginTop: '60px' }}>
                    <h3 className="widget-title mb-20" style={{ marginBottom: '20px' }}>  {Translate[isLang]?.ContactUSTitle}</h3>
                    <p className="mb-40">{Translate[isLang]?.ContactUS}</p>
                    <Link to={'/contact'}>
                      <button className="widget-btn mt-20" style={{ marginTop: '20px' }}>{Translate[isLang]?.ContactUSBtn}  </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          }

          {
            type === "blockchain" &&
            <div className=" row gx-4 gx-xxl-5">
              <div className="col-lg-8">
                <div className="widget-left-section">
                  {
                    isLang === "en" ?
                      <>
                        <h5> <strong className="skills_title"> Vigtas :</strong>    Forging the Future of Business with Expert Blockchain Developments <br /></h5>
                        <p className="text-white"> Vigtas stands as a trailblazing software company in the world of blockchain technology, spearheading innovations that redefine industries and empower businesses. Our dedicated team of blockchain experts is committed to delivering cutting-edge solutions that harness the potential of distributed ledgers to drive transparency, security, and efficiency across diverse sectors.
                        </p>


                      </> :
                      <>
                        <h5>  <strong className="skills_title"> Vigtas :</strong> صياغة مستقبل الأعمال من خلال التطورات الخبيرة في Blockchain <br /></h5>
                        <p className="text-white mt-3">
                          تقف Vigtas كشركة برمجيات رائدة في عالم تقنية blockchain ، وتقود الابتكارات التي تعيد تعريف الصناعات وتمكن الشركات. يلتزم فريقنا المتخصص من خبراء blockchain بتقديم حلول متطورة تسخر إمكانات دفاتر الأستاذ الموزعة لدفع الشفافية والأمان والكفاءة عبر مختلف القطاعات.                        </p>
                      </>
                  }
                  <hr />
                  <h3 className="sect-title-two fw-bold mb-20" style={{ marginBottom: '20px' }}>{Translate[isLang]?.Blockchain?.Specialization}  </h3>

                  <h6 className="text-white">{Translate[isLang]?.Blockchain?.SpecializationTitle}   </h6>
                  <p className="text-white mt-15 mb-45" style={{ marginBottom: '45px', marginTop: '15px' }}>
                    <ul className={`${isLang === "en" ? 'text-list text-listEn' : 'text-list text-listAr'} list-none  `}>
                      {
                        Translate[isLang]?.Blockchain?.SpecializationList?.map((item, index) => (

                          <li> <i className="pi pi-check"></i>
                            <strong className='skills_title'> {item.title}  </strong> {item.dec}
                          </li>
                        ))
                      }

                    </ul>
                  </p>
                  <hr />
                  <h3 className="sect-title-two fw-bold mb-20" style={{ marginBottom: '15px', marginTop: '45px' }}>{Translate[isLang]?.Blockchain?.SkillsTitle}  </h3>
                  <p className="text-white"> {Translate[isLang]?.Blockchain?.SkillsDec}            </p>
                  <p className="text-white mt-5 mt-15 mb-45" style={{ marginBottom: '45px', marginTop: '15px' }}>
                    <ul className={`${isLang === "en" ? 'text-list text-listEn' : 'text-list text-listAr'} list-none  `}>
                      {
                        Translate[isLang]?.Blockchain?.SkillsList?.map((item, index) => (

                          <li> <i className="pi pi-check"></i>
                            <strong className='skills_title'> {item.title}  </strong> {item.dec}
                          </li>
                        ))
                      }


                    </ul>
                  </p>
                  <hr />
                  <Technology />

                </div>



              </div>
              <div className="col-lg-4 order-sm-first order-md-first">
                <div className="widget-right-section mb-40">
                  <ul className="list-none service-widget">
                    <li className={`nav-item ${location.pathname === '/services/mobile' ? 'active bg-blue-900' : ''}`}>
                      <Link className="nav-link" to="/services/mobile">{Translate[isLang]?.Service1}</Link>
                    </li>

                    <li className={`nav-item ${location.pathname === '/services/management' ? 'active bg-blue-900' : ''}`}>
                      <Link className="nav-link" to="/services/management"> {Translate[isLang]?.Service2}  </Link>
                    </li>

                    <li className={`nav-item ${location.pathname === '/services/ai' ? 'active bg-blue-900' : ''}`}>
                      <Link className="nav-link" to="/services/ai">{Translate[isLang]?.Service3}</Link>
                    </li>

                    <li className={`nav-item ${location.pathname === '/services/blockchain' ? 'active bg-blue-900' : ''}`}>
                      <Link className="nav-link" to="/services/blockchain">{Translate[isLang]?.Service4}</Link>
                    </li>                  </ul>
                  <div className="widget-form mt-60" style={{ marginTop: '60px' }}>
                    <h3 className="widget-title mb-20" style={{ marginBottom: '20px' }}>  {Translate[isLang]?.ContactUSTitle}</h3>
                    <p className="mb-40">{Translate[isLang]?.ContactUS}</p>
                    <Link to={'/contact'}>
                      <button className="widget-btn mt-20" style={{ marginTop: '20px' }}>{Translate[isLang]?.ContactUSBtn}  </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </Container>
    </div>
  )
}

export default Details
