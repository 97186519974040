
import React, { useContext } from 'react'
import img from '../assets/Img'
import './header.scss'
import { Link } from 'react-router-dom'
import { LocalizationContext } from '../context/LangChange'
const HeaderBase = ({ title }) => {
    let { isLang } = useContext(LocalizationContext);

    return (
        <div>
            <div className="page-title-area  pt-lg-120 pb-lg-125 pb-md-100" dir={isLang==="en"?'ltr':'rtl'}>
                <img className="page-title-shape shape-one " src={img.star1} alt="shape1" />
                <img className="page-title-shape shape-three" src={img.star3} alt="shape2" />
                <img className="page-title-shape shape-four d-none d-md-inline-block " src={img.star4} alt="shape3" />
                <img className="page-title-shape shape-five d-none d-lg-inline-block " src={img.AnimationLine} alt="shape4" />
                <img className="page-title-shape shape-six" src={img.star2} alt="shape5" />
                <img className="page-title-shape shape-seven" src={img.star2} alt="shape6" />
                <img className="page-title-shape shape-eight d-none d-xl-inline-block" src={img.headerLeft} alt="img" />
                <img className="page-title-shape shape-nine d-none d-xl-inline-block" src={img.headerRight} alt="img" />

                <div className=" ">
                    <div className="row gx-4 gx-xxl-5 align-items-center">
                        <div className="col-xl-6 col-md-6">
                            <div className="page-title-wrapper text-md-start text-center">
                                <h2 className="page-title mb-20">{title}</h2>
                                <nav aria-label="breadcrumb">
                                    <ul className="breadcrumb list-none justify-content-center justify-content-md-start">
                                        {/* <li><Link to="/">{isLang ==="en" ?"Home":' رئيسية  ' }</Link></li>
                                        <li><Link to="/contact">{isLang === "en" ? "Contact Us" : 'اتصل بنا'}</Link></li>
                                        <li className="active" aria-current="page">{title}</li> */}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default HeaderBase
