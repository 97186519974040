import AOS from 'aos';
import React, { useContext, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import img from './../../assets/Img';
import { LocalizationContext } from '../../context/LangChange';
import Translate from './Translate'
import { Player } from '@lottiefiles/react-lottie-player';

const About = () => {
    let { isLang } = useContext(LocalizationContext);

    useEffect(() => {
        AOS.init({
            duration: '1500'
        });
    }, []);
    return (
        <div className='app__about overflow-hidden'>
            <div className="title" data-aos="flip-left"
                data-aos-anchor-placement="bottom-bottom">
                <h2 className="sect-title text-center">{ Translate[isLang]?.about?.header}</h2>
            </div>

            <div className="content ">
                <Container>
                    <Row dir={isLang === "en" ? 'ltr' : 'rtl'}>
                        <Col xl={6} lg={6} md={12} sm={12} className=' '>
                            {/* <img src={img.HomeAbout} className='w-100' alt="image about us" data-aos="fade-up" /> */}
                            <Player
                                // src="https://assets3.lottiefiles.com/packages/lf20_ZJR0xnKKys.json"
                                src='https://lottie.host/92c4c642-d700-4dcd-b87c-9b47a89e1c6f/VIeKqHvk5s.json'
                                autoplay
                                loop
                            />
                        </Col>

                        <Col xl={6} lg={6} md={12} sm={12} className='d-flex flex-column justify-content-center' >
                            <div className="block-style-seven pl-90 mb-30 pl-md-0">
                                {/* <h6 className="sub-title-two mb-20" data-aos="fade-up" >About Us</h6> */}
                                {/* <h2 className="sect-title mb-20" data-aos="fade-up" data-aos-delay={`  200`}  >{ Translate[isLang].about.title} </h2> */}
                                <p data-aos="fade-up" data-aos-delay={`  200`} >{ Translate[isLang]?.about?.dec} </p>
                                
                                <div className="icon-box" data-aos="fade-up" data-aos-delay={`  200`}>
                                    <span><img src={img.Icons1} alt="icon" /></span>
                                    <span><img src={img.Icons2} alt="icon" /></span>
                                    <span><img src={img.Icons3} alt="icon" /></span>
                                    <span><img src={img.Icons4} alt="icon" /></span>
                                    <span><img src={img.Icons5} alt="icon" /></span>
                                </div>
                                <Link to={'/about'}  >
                                    <div className="btn__main" data-aos="fade-up">
                                        <button className="btn theme_btn"  >{ Translate[isLang]?.about?.btn}  </button>
                                    </div>
                                </Link>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default About
